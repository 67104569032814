body {
  font-family: 'nunitoRegular';
  font-size: 14px;
  margin: 0;
  padding: 0;
}
$font: 'nunitoRegular';

* {
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
}
.table {
  background-color: white;
  margin-bottom: 20px;
  a {
    cursor: pointer;
  }
}
.table-head--dark {
  background-color: $primary-dark;
  color: #d1e8ff;
}
.table-head--blue {
  background-color: $blueShade-2;
  color: #d1e8ff;
}
.table-head-row {
  border: none;
  &__cell {
    border: 1px dashed $blueShade-1;
    text-indent: 8px;
    text-align: center;
    &--sno {
      width: 50px;
    }
    &--empty-left {
      border-left: none;
    }
    &--empty-right {
      border-right: none;
    }
    &--marker-cell {
      background-color: #5789ba;
      color: white;
      border-right: 1px solid $blueShade-1;
      border-left: 1px solid $blueShade-1;
    }
    &--status-cell {
      background-color: #d1e8ff;
      color: #5789ba;
    }
    &--status-cell,
    &--marker-cell {
      text-indent: 0;
      width: 100px;
      text-align: center;
    }
    &__width {
      width: 80px;
    }
  }
}

.table-body {
  position: relative;
  .table-row {
    &__cell {
      text-indent: 0;
      border: 1px dashed $blueShade-1;
      border-bottom: 1px dashed $blueShade-1;
      &--empty-left {
        border-left: 1px solid #ddd;
      }
      &--empty-right {
        border-right: 1px solid #ddd;
      }
      &--marker-cell {
        border: 1px dashed $blueShade-1;
        border-right: 1px solid $blueShade-1;
        border-left: 1px solid $blueShade-1;
        background-color: #f5f5f5;
      }
      &--status-cell {
        background-color: #fafafa;
      }
      &--student-cell {
        color: $primary-color;
        a {
          color: inherit;
        }
      }
      &--status-cell,
      &--marker-cell {
        width: 100px;
        text-align: center;
      }
    }
    &--numbered {
      td:first-child:before {
        position: absolute;
        transform: translateX(-28px);
        color: $primary-color;
        content: attr(data);
      }
    }
  }
}

.table-row__cell {
  vertical-align: middle !important;
  &__center {
    text-align: center;
  }
}
.table-row__cell--present {
  color: $primary-color;
}
.table-row__cell--absent {
  color: $red;
}

.custom-drop-down-wrapper {
  position: relative;
  margin-left: 10px;
}

.custom-drop-down {
  transition: all 0.2s;
  display: none;
  position: absolute;
  box-sizing: border-box;
  left: 0;
  top: 40px;
  z-index: 111;
  background-color: white;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  border: 1px solid $light-1;
  white-space: nowrap;
  &--visible {
    display: inline-block;
  }
  &__header {
    padding: 10px 20px;
  }

  &__body {
    border: 1px solid $light-1;
    border-right: none;
    border-left: none;
    height: 125px;
    overflow-y: scroll;
    transition: all 0.2s;
  }
  &-items {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    padding: 10px 0px;
    &__item {
      padding: 5px 20px;
      &:hover {
        background-color: $light-1;
        cursor: pointer;
      }
    }
  }
  &__text-field {
    display: flex;
    align-items: center;

    span[class~='fa-times'] {
      color: white;
      font-size: 20px;
    }
    input {
      border: 1px solid darken($light-1, 20%);
      border-radius: 4px;
      color: $primary-dark;
      padding: 5px 10px;
      outline: none;
      transition: all 0.2s;
      width: 100%;
      background-color: white;
      margin-right: 10px;
      &:focus,
      &:hover {
        box-shadow: 0 2px 5px rgba(1, 1, 1, 0.3);
      }
      &::placeholder {
        color: $blueShade-2;
      }
    }
    &--error {
      input {
        border: 1px solid $red;
        box-shadow: 0 2px 5px rgba(1, 1, 1, 0.3);
      }
    }
  }
  &__toggle {
    border: 1px solid darken($light-1, 20%);
    border-radius: 4px;
    color: $primary-dark;
    padding: 5px 10px;
    padding-right: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    &--error {
      border: 1px solid $red;
      box-shadow: 0 2px 5px rgba(1, 1, 1, 0.3);
    }
  }
}
.info-title {
  margin: 15px 0;
  color: #072645;
  font-size: 16px;
  text-transform: uppercase;
  &--gallerytile {
    color: #54c7fc;
  }
}
.info-row-certificate {
  border-right: none;
  border-left: none;
  padding: 4px 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: -1px;
  position: relative;
}
.info-row {
  border: 1px dashed $blueShade-1;
  border-right: none;
  border-left: none;
  padding: 8px 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: -1px;
  position: relative;
  &--truncate-txt {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dash {
    width: 1px;
    align-self: stretch;
    position: relative;
    &::after {
      content: '';
      display: block;
      height: calc(100% + 16px);
      width: 1px;
      border-right: 1px dashed $blueShade-1;
      position: absolute;
      top: -8px;
      left: 0;
      z-index: 0;
    }
  }

  .circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    margin: 0 20px 0 10px;
    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid $primary-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  input[type='radio'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    &:checked + .circle {
      background-color: $primary-dark;
    }
  }
}

.info-cell {
  margin-bottom: 0;
  font-weight: normal;
  display: flex;
  // align-items: center;
  margin-left: 10px;
  min-height: 30px;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  &__datepicker {
    flex-direction: row;
  }
  &__field {
    border: 1px solid darken($light-1, 20%);
    border-radius: 4px;
    color: $primary-dark;
    padding: 5px 10px;
    outline: none;
    transition: all 0.2s;
    width: 100%;
    background-color: white;
    &:focus,
    &:hover {
      box-shadow: 0 2px 5px rgba(1, 1, 1, 0.3);
    }
    &::placeholder {
      color: $blueShade-2;
    }
  }

  &__error {
    color: $red;
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 8px;
  }
  &__marginleft {
    margin-left: 10px;
  }
  &__confirm-head {
    color: $blueShade-2;
    text-transform: uppercase;
    margin-left: 10px;
  }
  &__displaydepartments {
    display: list-item;
    margin-left: 25px;
  }
  &--left {
    color: $blueShade-2;
    text-transform: uppercase;
    margin-left: 0;
    flex: 0 0 40%;
  }
  &--right {
    color: $blueShade-2;
    text-transform: uppercase;
    margin-right: 0;
    flex: 0 0 40%;
  }
  &--student-quality {
    flex: 0 0 20%;
  }
  &--review {
    flex: 0 0 80%;
  }
  &--no-margin {
    margin-left: 0px;
  }
  &--multiple-items {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    & > span {
      display: flex;
      justify-content: space-between;
      flex: 1;
      width: 100%;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &--contacts-admissions {
    flex: 1;
    // flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    & > span {
      display: flex;
      justify-content: space-between;
      flex: 1;
      width: 100%;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__contact-owner {
    flex: 0 0 25px;
    text-align: center;
    color: white;
    background-color: $primary-color;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
}

input[class~='info-cell'],
select[class~='info-cell'],
textarea[class~='info-cell'] {
  border: 1px solid darken($light-1, 20%);
  border-radius: 4px;
  color: $primary-dark;
  padding: 5px 10px;
  outline: none;
  transition: all 0.2s;
  width: 100%;
  background-color: white;
  &:focus,
  &:hover {
    box-shadow: 0 2px 5px rgba(1, 1, 1, 0.3);
  }
  &::placeholder {
    color: $blueShade-2;
  }
}
select[class~='info-cell'] {
  cursor: pointer;
  flex: 0 0 35%;
}
.last-row {
  position: relative;
  .info-row {
    // background-color: red;
    &:last-child {
      input[type='date'],
      input[type='email'],
      input[type='text'] {
        flex: 0 0 35%;
      }
    }
  }
  .calender--datepicker .react-datepicker__input-container {
    input[class~='info-cell'] {
      width: 100%;
    }
  }
  .button--red {
    cursor: pointer;
    position: absolute;
    right: 46px;
    bottom: 8px;
  }
}
.render-fields {
  position: relative;
  .button--white {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 8px;
  }
}
label[class~='info-cell__radio'] {
  cursor: pointer;
  color: $primary-dark;
  flex-direction: row;
  align-items: center;
  flex: 0 0 auto;
}

input[class~='info-cell--no-left-margin'],
select[class~='info-cell--no-left-margin'] {
  width: 100%;
  margin-left: 0;
}

#modal-body-certificate {
  overflow: visible;
}
.info-row-no-dashes {
  border: 1px;
  border-right: none;
  border-left: none;
  padding: 8px 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: -1px;
  position: relative;
  &--truncate-txt {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.react-datepicker {
  width: 210px;
  font-size: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  &__triangle {
    border-bottom-color: $primary-color !important;
  }
  &__header {
    background-color: $primary-color;
  }
  &__current-month {
    font-size: 1.5rem;
    color: white;
  }
  &__day-name {
    color: white;
    font-size: 1.2rem;
  }
  &__month-container {
    width: 100%;
  }
  &__day--selected,
  &__day--today {
    color: white;
    border-radius: 0.3rem;
  }
  &__day--selected {
    background-color: darken($primary-color, 20%);
  }
  &__day--today {
    background-color: $red;
    &:hover {
      background-color: darken($red, 20%);
    }
  }
  &__navigation {
    &--next {
      border-left-color: white;
    }
    &--previous {
      border-right-color: white;
    }
  }
}
.addbtn {
  border: 1px solid #006dc6 !important;
}
