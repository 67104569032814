.button,
.buttons {
  margin-right: 25px;
  border-radius: 8px;
  border: none;
  text-transform: uppercase;
  transition: all 0.2s;
  border: 1px solid transparent;
  padding: 6px 25px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: 'nunitoSemibold';
  cursor: pointer;

  &:focus,
  &:hover {
    text-decoration: none;
    box-shadow: 0 2px 5px rgba(1, 1, 1, 0.5);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $light-5;
  }

  &--notices {
    float: right;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  &--circle {
    border-radius: 50%;
    height: 130px;
    width: 130px;
    border: 1px solid #d1e8ff;
    border-color: $red;
    box-shadow: 0 2px 5px rgba(1, 1, 1, 0.2);
  }

  &--short {
    font-size: 12px;
    padding: 3px 8px;
  }

  &--squared {
    border-radius: 0;
    border-color: $red;
  }

  &--green-border {
    color: $green;
    border-color: $green;
  }

  &--white {
    border: 1px solid $light-1;
    color: $primary-color;
    background-color: $white;

    // padding: 5px;
    // border-radius: 2px;
    &:disabled {
      background-color: darken($white, 7%);
    }

    &:hover,
    &:focus {
      outline: none;
      box-shadow: -5px 0px 5px rgba(0, 0, 0, 0);
      background-color: #d1e8ff;
      color: #5789ba;
      border-color: darken($light-1, 10%);
    }

    &__active-class {
      outline: none;
      box-shadow: -5px 0px 5px rgba(0, 0, 0, 0);
      background-color: #d1e8ff;
      color: #5789ba;
      border-color: darken($light-1, 10%);
    }
  }

  &--blue {
    color: white;
    background-color: $primary-color;

    &:disabled {
      background-color: lighten($primary-color, 7%);
    }
  }

  &--bordered {
    background-color: transparent;
    color: $primary-color;
    border: 2px solid $primary-color;
  }

  &--subSection {
    background-color: #518bd3;
    color: #ffffff;
    float: left;
  }

  &--orange {
    color: white;
    background-color: orange;

    &:disabled {
      background-color: lighten($primary-color, 7%);
    }
  }

  &--redstatus {
    color: white;
    background-color: $red;
    opacity: 0.8;

    &:hover {
      text-decoration: none;
      box-shadow: none;
    }

    &:disabled {
      background-color: lighten($red, 7%);
    }
  }

  &--red {
    color: white;
    background-color: $red;

    &:disabled {
      background-color: lighten($red, 7%);
    }
  }

  &--orangestatus {
    color: white;
    background-color: orange;
    opacity: 0.8;

    &:hover {
      text-decoration: none;
      box-shadow: none;
    }

    &:disabled {
      background-color: lighten($red, 7%);
    }
  }

  &--greenstatus {
    color: white;
    background-color: $green;
    opacity: 0.8;

    &:hover {
      text-decoration: none;
      box-shadow: none;
    }

    &:disabled {
      background-color: lighten($red, 7%);
    }
  }

  &--green {
    background-color: $green;
    color: white;

    &:disabled {
      background-color: lighten($green, 7%);
    }
  }

  &--orange {
    background-color: $orange;
    color: white;

    &:disabled {
      background-color: lighten($orange, 7%);
    }
  }

  &--absolute {
    position: absolute;
    right: 30px;
    bottom: 20px;
  }

  &:last-child {
    margin-right: 10px;
  }

  &:focus {
    outline: none;
  }

  span[class~='fa'] {
    margin-right: 10px;
    font-size: 16px;
  }

  &--round {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    font-size: 18px;
    padding: 0;

    span[class~='fa'] {
      margin-right: 0px;
    }
  }

  &--round__timetable {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    font-size: 18px;
    margin: 0 5px 5px 0;
    display: flex;
    justify-content: flex-end;

    span[class~='fa'] {
      margin-right: 0px;
    }
  }

  &--no-mg-right {
    margin-right: 0;
  }
}

.button--modify {
  margin-top: 5px;
  text-transform: none;
  padding: 3px 10px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.modal-content {
  box-shadow: none !important;
}

a.button {
  display: inline-block;
  text-decoration: none;

  a:hover {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
    color: $primary-color;
  }
}

a.button--blue {
  &:hover {
    text-decoration: none !important;
    color: #fff;
  }
}

.container-fluid {
  a:hover {
    text-decoration: none;
  }
}

.buttonalign__center {
  display: flex;
  justify-content: center;

  &--margins {
    margin-top: 5px;
  }
}

.quick-link {
  margin-right: 25px;
  width: 175px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
  transition: all 0.2s;
  padding: 7px 10px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  // color: #54c7fc;
  color: #5789ba;
}

.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-end-container {
  display: flex;
  justify-content: flex-end;
}

.btn-cms {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button__wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-container {
  padding: 8px 0;

  .btn-right {
    margin-right: 20px;
    margin-top: 5px;
    padding: 3px 10px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 3px;

    span[class~='fa'] {
      margin-right: 5px;
    }

    &-reset {
      background: #ffffff;
      color: $primary-color;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &-filter {
      margin-right: 0;
      background: #ffffff;
      color: $primary-color;
    }

    &-print,
    &-csv {
      background-color: $primary-color;
      color: white;
    }

    &-disable {
      background-color: #ffffff;
      color: $primary-color;
      margin-right: 0;
    }

    &-csv {
      margin-right: 0;
    }

    &-delete {
      background-color: #fe3824;
      color: white;
      margin-right: 0;
    }
  }

  .slash {
    margin: 0 10px;
    display: inline-block;
    height: 100%;
    width: 1px;
    // background-color: $primary-color;
  }
}

.btn--right {
  float: right;
  margin-right: 0;
  margin-left: 25px;

  &--top {
    margin-top: 5px;
  }
}

.button--center {
  display: flex;
  justify-content: center;
}

.button--show {
  margin: 0 180px 0 15px;
  outline: none;
  border: 0;
  color: $primary-color;
  font-size: 18px;
  background-color: transparent;
  cursor: pointer;
}

//query chat
.edit-button {
  cursor: pointer;
  color: $primary-dark;
}

.chat-button {
  background-color: transparent;
  cursor: pointer;
  padding: 3px 7px;
  outline: none;
  border: 1px solid $primary-color;
  border-radius: 4px;

  &__outlined {
    color: $primary-color;
  }

  &__blue {
    background-color: $primary-color;
    color: white;

    &:disabled {
      cursor: not-allowed;
      background-color: lighten($primary-color, 7%);
    }
  }
}
