@mixin stylePTag {
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #072645;
}
.confirm-group-ctr {
  @extend .sms-form-ctr;
}
.confirm-group-form {
  @extend .sms-form-ctr;
}
.attd-entry-wrapper {
  @extend .marks-entry-wrapper;
}
.testsMainDiv {
  @extend .subjectsMainDiv;
  &__top {
    @extend .subjectsMainDiv__top;
  }
  .displayTests {
    @extend .displaySubjects;
  }
  .singleTest {
    @extend .singleSubject;
  }
  .testName {
    @extend .subjectName;
    .testCircle {
      @extend .subjectCircle;
    }
    p {
      @include stylePTag;
    }
  }
}
