@font-face {
  font-family: 'nunitoRegular';
  src: url('../../fonts/nunito-regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/nunito-regular.woff') format('woff'),
    url('../../fonts/nunito-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'nunitoLight';
  src: url('../../fonts/nunito-light.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/nunito-light.woff') format('woff'),
    url('../../fonts/nunito-light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'nunitoSemibold';
  src: url('../../fonts/nunito-semibold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/nunito-semibold.woff') format('woff'),
    url('../../fonts/nunito-semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'nunitoBold';
  src: url('../../fonts/nunito-bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/nunito-bold.woff') format('woff'),
    url('../../fonts/nunito-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
