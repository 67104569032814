$primary-color: #006dc6;
$primary-dark: #072645;
$primary-light: #54c7fc;

$blueShade-1: #9bbdde;
$blueShade-2: #5789ba;
$blueShade-3: #9bbdde;
$blueShade-4: #e5f7ff;

$light-1: #fafafa;
$light-2: #f5f5f5;
$light-3: #d1e8ff;
$light-4: #dedede;
$light-5: #d1d1d1;

$dark-1: #073b58;
$dark-2: #393838;

$white: #ffffff;
$red: #fe3824;
$green: #44db5e;
$orange: #fcb654;

$green-light: #a4ffb3;
