.Input {
  &--password {
    display: flex;
    flex: auto;
    flex-direction: row;
    justify-content: space-between;
    button {
      background-color: transparent;
      border: 0px #fff;
    }
  }
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid $primary-color;
  padding-bottom: 3px;
  margin-bottom: 5px;
  &--full {
    width: 100%;
  }
  input {
    outline: none;
    border: 0;
    color: $primary-color;
    background: transparent;
    font-family: $font;
    flex: 1 0 auto;
    font-size: 14px;
    font-weight: 300;
    &:focus {
      color: $primary-color;
      ~ label {
        opacity: 1;
      }
    }
    &[type='text'] {
      ~ label {
        &::before {
          content: '\f007';
        }
      }
    }
    &[type='password'] {
      ~ label {
        &::before {
          content: '\f023';
        }
      }
    }
    &[type='number'] {
      ~ label {
        &::before {
          content: '\f095';
        }
      }
    }
    &[type='input'] {
      ~ label {
        &::before {
          content: '\f095';
        }
      }
    }
  }
  label {
    font-family: FontAwesome;
    font-size: 18px;
    opacity: 0.5;
    transition: opacity 0.5s ease;
    &::before {
      color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
    }
  }
}

.pswd-user-select:hover {
  cursor: pointer;
}
.error-text {
  display: none;
  position: relative;
  top: 10px;
  color: $red;
}

.error {
  .Input {
    border-bottom: 1px solid $red;
    input {
      color: $red;
    }
    label {
      opacity: 1;
      &::before {
        color: $red;
      }
    }
  }
  .error-text {
    display: block;
  }
}
input:required::after {
  content: ' *' !important;
  margin: 0 0 0 20px;
}

.Search-Bar22 {
  background-color: #fafafa;
  border: 2px solid #fafafa;
  border-right: 1px solid $blueShade-1;
  border-top: none !important;
  padding: 3px;

  input {
    width: 100%;
    height: 30px;
    padding: 0 5px;
    font-size: 14px;
    font-weight: lighter;
    border-radius: 4px;
    border: 1px solid darken(#fafafa, 10%);
    &::placeholder {
      font-size: 14px;
      color: #9bbdde;
    }
  }
}
.attd-search {
  p.pull-left {
    margin-bottom: 0;
    color: #072645;
    font-size: 16px;
    text-transform: uppercase;
  }
  span[class~='fa-pencil'] {
    color: #9bbdde;
  }
}
.attd-search.report-search {
  height: 50px;
  &::after {
    content: '';
    position: absolute;
    bottom: -75px;
    width: 100%;
    height: 90px;
    background-color: #fafafa;
    border-bottom: 1px solid $light-5;
    z-index: -111;
  }
}

.marks-field {
  font-weight: normal;
  display: flex;
  width: 200px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  &--smallwidth {
    width: auto;
  }
  &__error {
    color: $red;
    font-size: 12px;
    text-transform: uppercase;
    padding-top: 5px;
  }

  input[type='text'],
  input[type='number'],
  input[type='input'] {
    text-align: center;
    width: 100px;
    border-radius: 4px;
    padding: 5px;
    border: 1px solid darken($light-1, 20%);
    transition: all 0.2s;
    &:hover,
    &:focus {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      outline: none;
      border: 1px solid $primary-color;
    }
  }
  input[type='number'],
  input[type='input'] {
    text-indent: 15px;
  }
  &--error {
    input[type='text'],
    input[type='number'],
    input[type='input'] {
      border: 1px solid $red;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }
  }
}
.max-marks-cell {
  display: flex;
  flex-direction: column;
  text-align: center;
  span {
    font-size: 16px;
    color: $primary-color;
  }
  .marks-field {
    &__error {
      color: $red;
      font-size: 12px;
      text-transform: uppercase;
      padding-top: 5px;
    }
  }
}
span[class~='fa-check'] {
  color: #44db5e;
  font-size: 20px;
}
span[class~='fa-spinner'] {
  color: $primary-light;
  font-size: 20px;
}
span[class~='fa-pause-circle'] {
  color: $orange;
  font-size: 20px;
}
span[class~='fa-unlock'] {
  color: $primary-color;
  font-size: 20px;
}
span[class~='fa-times'] {
  color: #fe3824;
  font-size: 20px;
}
.st-login-form {
  margin: 0 auto;
  top: 50%;
  transform: translateY(10%);
  max-width: remy(380px);
  background: transparent;
  .text-small {
    display: block;
    margin-top: 20px;
    color: $primary-color;
  }
  label {
    margin-bottom: 0px;
  }
  .error {
    color: $red;
  }
  .btn-primary {
    margin-top: 30px;
    height: 40px;
    border: none;
    border-radius: 14px;
    background: $primary-color;
    &:hover {
      transform: scale(1.02);
    }
    &:active {
      transform: scale(0.99);
    }
  }
}
.alert {
  color: red;
}
.success-alert {
  color: green;
}
.st-tests-form {
  input#order,
  input#percentage,
  input#term,
  input#subTestMaxMarks,
  input#subTestMinMarks {
    border-radius: 4px;
    outline: none;
    padding: 5px 10px;
    border: 1px solid $light-1;
    width: 90%;
  }

  .fields-ctr--no-border {
    border-bottom: none;
  }
  .fields-ctr--has-3-fields {
    justify-content: space-between;
    .field {
      flex: 0 0 30%;
    }
  }
}
.class-details {
  .class-field--70 {
    width: 70%;
  }
}
.check-tab-ctr {
  margin: 4px 4px 4px 10px;
  display: inline-block;
  cursor: pointer;
  .check-tag {
    display: inline !important;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:hover,
    &:active,
    &:focus,
    .check-tag__input:focus + span {
      outline: none;
      background-color: #bbb;
    }
    input[type='checkbox']:focus + span {
      background-color: $primary-color;
      color: #fff;
    }
    input[type='checkbox']:checked + span {
      background-color: $primary-color;
      color: #fff;
    }
    input[type='radio']:focus + span {
      background-color: $primary-color;
      color: #fff;
    }
    input[type='radio']:checked + span {
      background-color: $primary-color;
      color: #fff;
    }

    .check-tag__input {
      position: absolute;
      visibility: hidden;
    }
    .check-tag__text {
      text-decoration: none;
      display: inline-block;
      padding: 3px 9px;
      border-radius: 3px;
      background-color: #ccc;
      color: #666;
    }
  }

  .check-tag--red {
    input[type='checkbox']:checked + span {
      background-color: #fe3824;
      color: #fff;
    }
  }

  .check-tag--green {
    input[type='checkbox']:checked + span {
      background-color: $green;
      color: #fff;
    }
  }
}
.new-group {
  height: 100%;
  &::after {
    content: '';
    display: block;
    width: 100vw;
    height: 200%;
    position: fixed;
    top: 0;
    background-color: $light-1;
    z-index: -111;
  }
  .tab {
    margin-left: 50px;
    background-color: $light-1;
    display: inline-block;
    padding: 10px 20px;
    border-top: 1px solid lighten($blueShade-2, 25%);
    border-bottom: none;
    position: relative;
    top: 0px;
    color: $blueShade-2;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 40px;
      height: 100%;
      background-color: $light-1;
      z-index: -1;
      top: 0;
      border: 1px solid lighten($blueShade-2, 25%);
    }
    &::before {
      border-radius: 5px 0 0 0;
      left: -10px;
      border-right: none;
      border-bottom: none;
      transform: skew(-11deg, 0deg);
    }

    &::after {
      border-radius: 0 5px 0 0;
      right: -10px;
      top: -1px;
      border-left: none;
      border-bottom: none;
      transform: skew(11deg, 0deg);
    }
  }
  .tab__text {
    font-size: 15px;
    margin-bottom: 0;
    color: #5789ba;
    text-transform: uppercase;
  }
}
.tab-nav-ctr {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $light-1;
  border-bottom: 1px solid $light-1;
  border-top: 1px solid lighten($blueShade-2, 25%);
  .tab-nav {
    color: $blueShade-2;
    display: flex;
    flex: 0 0 65%;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 22;
    @include for-large-devices-down {
      flex: 0 0 77%;
    }
    @include for-medium-devices-down {
      flex: 0 0 95%;
    }
    @include for-small-devices-down {
      flex: 0 0 95%;
      flex-wrap: wrap;
    }
  }
  .tab-nav--status {
    color: $blueShade-2;
    display: flex;
    flex: 0 0 99%;
    justify-content: space-around;
    align-items: center;
    position: relative;
    z-index: 22;
  }
  .navigation {
    background-color: $light-1;
    padding: 0;
    display: flex;
    align-items: center;
    &__text {
      margin-bottom: 0;
      font-weight: bold;
    }
    &__breadcrumbs {
      margin-bottom: 0;
      font-size: 12px;
    }
    &__num {
      margin-right: 5px;
      display: inline-block;
      width: 24px;
      height: 27px;
      font-size: 13px;
      line-height: 27px;
      text-align: center;
      border-radius: 50%;
      background-color: $blueShade-2;
      color: white;
    }
    &--active {
      color: $primary-color;
      .navigation__num {
        background-color: $primary-color;
      }
    }
    &--success {
      color: $primary-dark;
      .navigation__num {
        background-color: $primary-dark;
      }
      .tick {
        margin-left: 5px;
        &::after,
        &::before {
          background-color: $primary-dark;
        }
        &::before {
          background-color: white;
        }
      }
    }
  }
}

.new-group-form-ctr {
  .container {
    background-color: white;
  }
  .form-ctr {
    position: relative;
    background-color: $light-1;
    margin: 40px 0;
    padding: 20px 40px;
    border: 1px solid lighten($blueShade-2, 25%);
    border-radius: 12px 12px 0 0;
  }
  .form__heading {
    color: $primary-dark;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .st-group-form {
    margin-bottom: 90px;
    input[type='text'],
    input[type='number'],
    input[type='input'],
    select {
      border-radius: 4px;
      outline: none;
      padding: 5px 10px;
      border: 1px solid $light-1;
      width: 90%;
      transition: all 0.2s;
      &:focus,
      &:hover {
        box-shadow: 0 2px 5px rgba(1, 1, 1, 0.3);
      }
    }
    select {
      color: #777;
      background-color: white;
    }
  }
  .fields-ctr {
    border-bottom: 1px dashed $blueShade-2;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-start;
  }
  .fields-ctr--has-1-fields {
    .field {
      flex: 0 0 111%;
    }
  }
  .fields-ctr--has-2-fields {
    .field {
      flex: 0 0 50%;
    }
  }
  .fields-ctr--no-border {
    border-bottom: none;
  }
  .field__label {
    display: block;
    color: $blueShade-2;
    font-size: 14px;
    font-weight: normal;
  }

  .section-details {
    margin-top: 20px;
  }

  .section-detail__heading {
    margin-bottom: 10px;
    color: $primary-dark;
    font-size: 16px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  ul {
    list-style-type: none;
    .section-details-feilds {
      position: relative;
      border-bottom: 1px dashed $blueShade-2;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
  }
  .section-fields {
    display: flex;
    flex-wrap: wrap;
  }
  .section-field {
    margin-bottom: 10px;
    &--50 {
      flex: 0 0 50%;
      margin-bottom: 10px;
    }
    &--100 {
      flex: 0 0 100%;
      input[type='text'] {
        width: 80%;
      }
    }
  }

  .section-num {
    width: 24px;
    height: 24px;
    display: inline-block;
    line-height: 24px;
    text-align: center;
    border: 1px solid $blueShade-2;
    color: $blueShade-2;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: -35px;
    margin-bottom: 0;
  }
}

.submit-field {
  background-color: $primary-dark;
  display: flex;
  justify-content: center;
  height: 90px;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 -2px 5px rgba(1, 1, 1, 0.3);
}
.submit-field-no-absolute {
  background-color: $primary-dark;
  display: flex;
  justify-content: center;
  height: 90px;
  align-items: center;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0 -2px 5px rgba(1, 1, 1, 0.3);
}
.calender--datepicker {
  display: flex;

  .fa-calendar {
    padding-left: 15px;
    font-size: 28px;
    color: #072645;
  }
}
.drop-down-menu {
  border: 1px solid $blueShade-1;
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: $light-1;
  .tab {
    top: 2px;
    height: 50px;
    border-color: $blueShade-1;
    &::before,
    &::after {
      top: -1px;
      z-index: 1;
      border-color: $blueShade-1;
      border-bottom: white;
    }
  }
  .drop-down {
    background-color: white;
    height: 30px;
    line-height: 30px;
    color: $primary-color;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s;
    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }
  }

  .drop-down > div:first-of-type {
    border: 1px solid darken($light-1, 10%);
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .drop-down-items {
    transition: all 0.2s;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    background-color: white;
    position: relative;
    z-index: 1;
    opacity: 0;
    height: 0;
    margin-top: -2px;
    overflow: hidden;
    div {
      margin-top: -1px;
      border: 1px solid darken($light-1, 10%);
      white-space: nowrap;
      padding: 0 10px;
      &:hover {
        background-color: darken($light-1, 5%);
      }
    }
  }
  .drop-down:hover > .drop-down-items {
    opacity: 1;
    height: auto;
  }
  .row-eq-height {
    display: flex;
    align-items: center;
  }
}
.info-row {
  .react-time-picker {
    // min-width: 100%;
    margin-left: 10px;
  }
  .react-time-picker__wrapper {
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    color: #072645;
    outline: none;
    transition: all 0.2s;
    background-color: white;
    padding: 5px 10px;
    min-height: 15px;
  }
}
.errorfalse {
  display: none;
}
.errortrue {
  display: block;
  color: #bf3121;
  margin: 15px 0 0 0;
}
input[disabled] {
  background: #dddddd;
}
.breadcrumb-nav {
  height: 50px;
  background: transparent;
  font-weight: bold;
  // margin-left: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .breadcrumb-nav-tab {
    color: #525252;
    display: flex;
    flex: 0 0 65%;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    // z-index: 22;
    @include for-large-devices-down {
      flex: 0 0 77%;
    }
    @include for-medium-devices-down {
      flex: 0 0 95%;
    }
    @include for-small-devices-down {
      flex: 0 0 95%;
      flex-wrap: wrap;
    }
    div {
      a {
        color: #525252;
      }
      p {
        .nav-icon {
          margin: 0 10px 0 10px;
        }
      }
    }
  }
}
.breadcrumb-nav-margin {
  margin-left: 15px;
}

.template__name {
  label {
    color: #072645;
  }
  input {
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    color: #072645;
    padding: 5px 10px;
    outline: none;
    transition: all 0.2s;
    width: 300px;
    margin: 0 0 25px 0;
  }
}

.test__textArea {
  width: 95%;
}
.test__textArea__padding {
  padding-left: 10px;
  padding-top: 5px;
}
.textarea {
  border-radius: 4px;
  outline: none;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 100%;
}

.question__wrapper {
  list-style: none;
  margin-bottom: 25px;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 15px;
  border-radius: 2px;
  -webkit-box-shadow: 0 10px 20px -10px rgba(50, 84, 190, 0.1);
  box-shadow: 0 10px 20px -10px rgba(50, 84, 190, 0.2);
  border: 1px solid #dbe4ff;
  background-color: #ffffff;
  position: relative;

  .answer__list {
    margin-left: 40px;
    margin-bottom: 5px;
  }
}
