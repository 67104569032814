@mixin for-extra-small-devices-down {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin for-small-devices-down {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin for-medium-devices-down {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin for-large-devices-down {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-down {
  @media (max-width: 1800px) {
    @content;
  }
}

@mixin for-phone-landscape-only {
  @media (min-width: 480px) and (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portait-only {
  @media (min-width: 600px) and (max-width: 909px) {
    @content;
  }
}
@mixin for-tablet-landscape-only {
  @media (min-width: 910px) and (max-width: 1199px) {
    @content;
  }
}

@mixin for-desktop-only {
  @media (min-width: 1200px) and (max-width: 1799px) {
    @content;
  }
}

@media (max-width: 767px) {
  .quote-and-form-container {
    height: 500px;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 20px !important;
  }
  .st-home-quote__text {
    margin-top: 0px;
  }
}
