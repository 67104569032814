html,
body {
  height: 100%;
  background-color: #f5f5f5;
}

#root {
  height: 100%;
  background-color: $light-2;
}

label {
  font-weight: normal !important;
}

.mandatory-field-info {
  background-color: white;
  padding: 5px 10px;
  color: $blueShade-2;
  font-size: 12px;
  opacity: 0.8;
  text-transform: uppercase;
  border-radius: 4px;
  border: 0.5px solid $blueShade-2;

  // box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
  .asterisk {
    font-size: 14px;
  }
}

.student-name {
  color: $primary-dark;
}

.clear {
  clear: both;
}

.page__container {
  position: relative;
  min-height: 100vh;
  padding: 0 0 50px 0;
}

.page__heading,
.page__subHead {
  font-size: 22px;
  text-transform: capitalize;
  padding: 0;
  margin: 0 0 20px 5px;
  color: $dark-1;
}

.page__subHead {
  color: $primary-dark;
  font-size: 18px;
  margin: 0px 0 25px 0;
}

.page__wrapper {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: 5px;
  margin-left: 20px;
}

.support__wrapper {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: 45px;
  margin-left: 45px;
}

.form-control {
  height: 40px !important;
}

.form__text-area {
  width: 100%;
  border: none;
  padding: 5px 10px;
  height: 70px;
  border-radius: 4px;
  // margin: 0 0 0 10px;
}

.deleteSubjectTable {
  border: 1px solid #818ba6;
  background-color: #ffffff;

  td,
  th {
    padding: 15px;
    width: 50%;
    border-bottom: 1px solid #818ba6;
    color: #1c2a4e;
    font-size: 16px;
  }

  th {
    padding: 25px 15px !important;
  }

  .button {
    margin: 0 !important;
    min-width: 130px;
    min-width: 125px;
    border-radius: 8px;
    font-size: 14px;
    padding: 5px 20px !important;
  }
}

.editClassSectionTab {
  .form-group {
    padding: 0 15px 0 0;
  }

  .button {
    margin: 15px auto;
    padding: 7px 20px;
  }

  .nav-tabs {
    border-bottom: 0;
    margin-bottom: 35px;
  }

  .fade.show {
    opacity: 1;
  }

  .nav-tabs a:hover,
  .nav-tabs a:focus {
    color: #555;
    cursor: default;
    background-color: #006dc6;
    color: #ffffff;
    border: 1px solid #818ba6;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0;
    text-decoration: none;
  }

  .nav-tabs a.active,
  .nav-tabs a.active:hover,
  .nav-tabs a.active:focus {
    color: #555;
    cursor: default;
    background-color: #006dc6;
    color: #ffffff;
    border: 1px solid #818ba6;
    text-decoration: none;
  }

  .nav-tabs a {
    margin-right: 0;
    line-height: 1.42857143;
    border: 1px solid #818ba6;
    color: #1c2a4e;
    border-radius: 0;
    background-color: #ffffff;
    padding: 7px 15px;
    cursor: pointer !important;
    text-decoration: none;
  }
}

.assignTeacher {
  width: 48%;
  margin: 0 2% 25px 0 !important;
  float: left;
  border-radius: 2px;
  -webkit-box-shadow: 0 10px 20px -10px rgba(50, 84, 190, 0.2);
  box-shadow: 0 10px 20px -10px rgba(50, 84, 190, 0.2);
  border: 1px solid #9fafd5;
  background-color: #ffffff;
  min-height: 300px;
  display: flex;
  flex-direction: column;

  .assignTeacher__select {
    margin-bottom: 15px;
  }

  .assignTeacher__subject {
    background-color: #ced9f4;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
    font-size: 20px;
    text-transform: capitalize;
    line-height: 30px;
    width: 100%;
  }

  .assignTeacher__body {
    padding: 20px;
    width: 100%;
  }

  .assignTeacher__select {
    .selector__field {
      color: $primary-color;
      width: 80%;
      float: left;

      .selector__field__icon--gray-bg {
        margin-right: -25px;
      }

      .selector__field__input {
        width: 80%;
      }

      .selector__field__icon--white-bg {
        color: $primary-color;
        display: none;
      }
    }

    .button {
      margin: 0;
      font-size: 22px;
      padding: 1px 10px;
      float: left;
      border-radius: 0;
    }
  }

  .info-cell {
    margin-left: 0;
    width: 100%;
    // .button.button--red {
    //   color: white;
    //   background-color: $primary-color;
    //   margin: 15px 0;
    // }
  }

  .teacher--border {
    padding: 3px 9px;
    border: 2px solid #3f3fff;
    background-color: white;
    border-radius: 8px;
  }

  .check-tab-ctr {
    margin: 0;
  }

  .check-tag {
    background-color: transparent;
    color: #1c2a4e;
  }

  .check-tab-ctr .check-tag:hover,
  .check-tab-ctr .check-tag:active,
  .check-tab-ctr .check-tag:focus,
  .check-tab-ctr .check-tag .check-tag__input:focus + span {
    outline: none;
    background-color: transparent;
  }

  .check-tab-ctr .check-tag--green input[type='checkbox']:checked + span {
    background-color: transparent;
    color: #1c2a4e;
    border: 2px solid #3f3fff;
    margin: 0 8px 8px 0;
    border-radius: 8px;

    &:hover {
      border: 2px solid $primary-color;
      background-color: $primary-color;
      color: #ffffff;
    }

    &:focus {
      border: 2px solid $primary-color;
      background-color: $primary-color;
      color: #ffffff;
    }
  }

  .check-tab-ctr .check-tag--red input[type='checkbox']:checked + span {
    border: 2px solid $primary-color;
    background-color: $primary-color;
    color: #ffffff;
    margin: 0 8px 8px 0;
  }
}

.noTeachersAssigned {
  margin: 0 0 25px 0;
  color: #333;
}

.editSubjectForm {
  label {
    color: #333 !important;
  }

  input,
  select {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc !important;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
  }
}

.subHead {
  color: #072645;
  font-size: 18px;
  margin: 0 0 15px 0;
  letter-spacing: 0;
  text-transform: capitalize;
  padding: 0;
}

.addElectiveGroup {
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin-bottom: 25px;
    }

    li.section-details-feilds {
      list-style: none;
      margin-bottom: 25px;
      width: 100%;
      margin: 0 0 30px 0;
      padding: 25px;
      border-radius: 2px;
      -webkit-box-shadow: 0 10px 20px -10px rgba(50, 84, 190, 0.2);
      box-shadow: 0 10px 20px -10px rgba(50, 84, 190, 0.2);
      border: 1px solid #dbe4ff;
      background-color: #ffffff;
      position: relative;

      .button__delete {
        position: absolute;
        right: 25px;
        top: 0px;
      }

      .field {
        width: 32.33%;
        margin-right: 1%;
        float: left;

        input {
          display: block;
          width: 100%;
          height: 34px;
          padding: 6px 12px;
          font-size: 14px;
          line-height: 1.42857143;
          color: #555;
          background-color: #fff;
          background-image: none;
          border: 1px solid #ccc;
          border-radius: 4px;
          -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          -webkit-transition: border-color ease-in-out 0.15s,
            -webkit-box-shadow ease-in-out 0.15s;
          -o-transition: border-color ease-in-out 0.15s,
            box-shadow ease-in-out 0.15s;
          transition: border-color ease-in-out 0.15s,
            -webkit-box-shadow ease-in-out 0.15s;
          transition: border-color ease-in-out 0.15s,
            box-shadow ease-in-out 0.15s;
          transition: border-color ease-in-out 0.15s,
            box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
        }
      }

      label {
        display: block;
      }
    }
  }
}

.confirm__tab {
  background-color: #fff;
  margin-bottom: 25px;
  border-radius: 8px;

  .navigation {
    background-color: transparent;
  }
}

.button__marTop25 {
  margin: 25px 0;
}

.mar-t25px {
  margin-top: 25px;
}

.mar-t10px {
  margin-top: 10px;
}

.confirm__tabBreadcrumb {
  margin: 0 0 25px 0;
  background-color: #fff;
  padding: 8px 10px;
  border: 1px solid #9bbdde;
}

.pageBg__top {
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 230px;
  padding: 95px 20px 50px 20px;
}

.box__wrapper {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 2px;
  box-shadow: 0 10px 20px -10px rgba(50, 84, 190, 0.2);
}

.leave-count-box {
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;

  .leave-count__content {
    // height: 225px;
    border: 1px solid #9bbdde;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    background-color: white;
    color: #5789ba;

    // width: 60%;
    .leave-count__head {
      font-weight: bold;
      font-size: 15px;
      text-transform: uppercase;
    }

    .leave-details {
      color: #a2a2a2;

      span {
        color: #505050;
      }
    }
  }
}

.leave-date {
  .selector__field {
    height: 25px + 5px;
    background-color: white;
  }
}

.leave-form-date {
  width: 100px;

  .selector__field {
    height: 25px + 5px;
    background-color: white;
  }

  .react-datepicker__input-container {
    width: 230px;
  }
}

.leave-status {
  &-complete {
    color: green !important;
  }

  &-partial {
    color: orange !important;
  }

  &-decline {
    color: red !important;
  }

  &-confidential {
    color: #5789ba !important;
  }
}

.attd-pie {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.bodyFillBg {
  background-color: #fafafa;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btnFile {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 10px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #54c7fc;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.fade-in-out {
  animation: fading 3s infinite ease-in-out;
  -webkit-animation: fading 3s infinite ease-in-out;
}

@keyframes fading {
  0% {
    opacity: 0.07;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.07;
  }
}

.img-box {
  display: flex;
  flex: 0 0 68px;
  height: 26px;
  background-color: white;
  padding: 5px 10px 5px 7px;
  border-radius: 6px;
  align-items: center;
}

.img-box__nissi-logo {
  height: 15px;
  width: 60px;
}

.icons {
  display: flex;
  flex: 0 0 30%;
  justify-content: space-around;
}

.st-social {
  color: #072645;
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 50%;
  line-height: 24px;
  text-align: center;
}

a.nav-items__item {
  color: white;
}

a.nav-items__item--active {
  background-color: white;
  color: $primary-color !important;

  span[class~='fa'] {
    color: $primary-color;
  }

  &::before,
  &::after {
    background-color: white;
  }
}

.divider-vertical {
  width: 5px;
  height: 100%;
  background-color: $blueShade-1;
}

.student-certificate-modal {
  margin-left: 10px;
  text-transform: none;
  overflow: visible;
}

.student-certificate-modal-footer {
  margin: 20px;
  overflow: visible;
}

.upload-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-content: center;
  align-items: center;

  input[type='text'] {
    margin: auto 20px;
    width: 250px;
  }

  input[type='file'] {
    font-style: italic;
    cursor: pointer;
  }

  .doc-upload-submit {
    justify-content: flex-end;
  }
}

.duefees-checkbox {
  margin-left: 10px;

  input[type='checkbox'] {
    margin-right: 15px;
  }
}

.attd-container {
  &::after {
    content: '';
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: -11111;
    display: block;
    background-color: #f5f5f5;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.show.fade {
  opacity: 1;
}

.attd-present {
  color: green;
}

.attd-absent {
  color: red;
}

.attd-attd-halfday {
  color: orange;
}

.attd-late-early {
  color: $orange;
}

.modal {
  &.show {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .table {
    margin-bottom: 0;
  }

  .modal-content {
    border-radius: 4px;
    border: none;
  }

  .modal-header {
    color: white;
    background-color: $primary-color;
    border-bottom: none;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    border-radius: 4px 4px 0 0;
    height: 40px;
    padding-left: 30px;
  }

  .modal-title {
    margin-right: auto;
    font-size: 16px;
  }

  .close {
    color: white;
    text-shadow: none;
    opacity: 1;
    font-size: 34px;
    font-weight: lighter;
    margin-top: 0;

    &:hover {
      color: darken(white, 20%);
    }
  }

  .slash {
    padding: 0 5px;
  }

  .count {
    padding: 0 30px;
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background-color: $light-1;

    p {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .large-text {
    height: 150px;
    padding: 0 30px;
    background-color: $light-1;

    p {
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .modal-body {
    padding: 0;
    overflow: hidden;

    &__generateApplication {
      padding: 20px 20px 60px 20px;
    }

    &__appointment {
      padding: 20px 20px 60px 20px;
      min-height: 250px;
      overflow-y: scroll;
    }

    &__assignFee {
      padding: 20px 20px 60px 20px;
    }

    &__editfee {
      padding: 20px 20px 60px 20px;
      max-height: 310px;
      overflow-y: scroll;
    }

    &__feereceipt {
      padding: 20px 20px 60px 20px;
      min-height: 250px;
      max-height: 350px;
      overflow-y: scroll;
    }

    &__approveAdmission {
      padding: 20px 20px 60px 20px;
    }
  }

  .warning {
    width: 100%;
    display: flex;
    flex-direction: column;

    &--red,
    &--blue {
      padding: 15px 20px;
      margin-bottom: 0;
    }

    &--red {
      background-color: $red;
      color: white;
    }

    &--blue {
      background-color: lighten($primary-color, 20%);
      color: $primary-dark;
    }
  }

  .modal-footer {
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.success,
.failure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: $light-1;
  font-size: 16px;
  color: #072645;

  .check-container,
  .times-container {
    border: 5px solid #44db5e;
    border-radius: 50%;
    margin-bottom: 10px;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: #44db5e;
    }
  }

  .times-container {
    border: 5px solid $red;

    span {
      color: $red;
    }
  }
}

.modal-wrapper {
  padding: 20px;
}

.modal-wrapper-inner {
  background-color: $light-1;
  border: 1px solid $blueShade-1;
  border-radius: 8px 8px 0 0;

  .modal-footer {
    justify-content: center;
    padding-top: 30px;
  }
}

.modal-dialog {
  margin: 100px auto !important;
}

.st-student-table {
  max-width: 99%;
  overflow-x: scroll;
  padding: 10px;
}

.table {
  background-color: white;
  // margin-bottom: 20px;
}

.table--bottom {
  margin-bottom: 0px;
}

.table-head-maxmarks {
  background-color: $blueShade-4;
  color: #5789ba;
  text-align: center;
  font-weight: bold;
}

.table-head--dark {
  background-color: $primary-dark;
  color: #d1e8ff;
}

.table-head-row {
  border: none;

  &__cell {
    border: 1px dashed $blueShade-1;
    text-indent: 8px;
    text-align: center;

    &--empty-left {
      border-left: none;
    }

    &--empty-right {
      border-right: none;
    }

    &--marker-cell {
      background-color: #5789ba;
      color: white;
      border-right: 1px solid $blueShade-1;
      border-left: 1px solid $blueShade-1;
    }

    &--status-cell {
      background-color: #d1e8ff;
      color: #5789ba;
    }

    &--status-cell,
    &--marker-cell {
      text-indent: 0;
      width: 100px;
      text-align: center;
    }

    &--pointer {
      cursor: pointer;
    }

    &--bold-font {
      font-weight: bold;
    }

    &--normal-font {
      font-weight: normal;
    }
  }

  &__subject:hover {
    cursor: pointer;
    color: black;
    text-decoration: underline;
  }
}

.table-body {
  position: relative;

  .table-row {
    &__cell {
      text-indent: 0;
      border: 1px dashed $blueShade-1;
      border-bottom: 1px dashed $blueShade-1;

      &--empty-left {
        border-left: 1px solid #ddd;
      }

      &--empty-right {
        border-right: 1px solid #ddd;
      }

      &--marker-cell {
        border: 1px dashed $blueShade-1;
        border-right: 1px solid $blueShade-1;
        border-left: 1px solid $blueShade-1;
        background-color: #f5f5f5;
      }

      &--status-cell {
        background-color: #fafafa;
      }

      &--status-cell,
      &--marker-cell {
        width: 100px;
        text-align: center;
      }

      &--toggle-cell {
        text-align: center;
      }

      // &--input-field {
      //   // display: flex;
      //   // justify-content: center;
      // }
    }
  }
}

.table-row__cell {
  &--green {
    color: green;
  }

  &--red {
    color: red;
  }

  &--button {
    display: flex;
    justify-content: center;
  }

  vertical-align: middle !important;
}

.table-row__cell--present {
  color: $primary-color;
}

.table-row__cell--absent {
  color: $red;
}

.table-row__cell--late {
  color: $orange;
}

.table-row__cell--unmarked {
  color: $dark-2;
}

.table-row__cell--disabled {
  // color: #ede9e8;
  color: #808080;
}

.lbl {
  position: relative;
  display: block;
  height: 20px;
  width: 44px;
  background: $red;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.lbl:after {
  position: absolute;
  left: -2px;
  top: -3px;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  content: '';
  transition: all 0.3s ease;
  border: 2px solid $red;
}

.half-leave-lbl {
  background: #808080;
  position: relative;
  display: block;
  height: 20px;
  width: 44px;
  // background: $red;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.half-leave-lbl:after {
  // background: #808080;
  position: absolute;
  left: -2px;
  top: -3px;
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  background: #fff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  content: '';
  transition: all 0.3s ease;
  border: 2px solid #808080;
}

.lbl:active:after {
  transform: scale(1.15, 0.85);
}

.cbx:checked ~ label {
  background: $primary-color;
}

.cbx:checked ~ label:after {
  border: 2px solid $primary-color;
  left: 20px;
}

.cbx:indeterminate ~ label {
  background: $dark-2;
}

.cbx:indeterminate ~ label:after {
  border: 2px solid $dark-2;
  left: 20px;
}

.cbx:disabled ~ label {
  background: #ede9e8;
}

.cntr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hidden {
  display: none;
}

.press {
  display: inline-block;
}

.press-half {
  display: block;
}

.date-and-class {
  color: #5789ba;
  font-size: 16px;
  margin-top: 5px;
}

.info-text {
  color: $primary-dark;
  font-size: 16px;
  margin-top: 5px;

  &--light {
    color: $light-3;
  }
}

.btn-position-tr {
  border-top: none;
  background-color: #f5f5f5;

  td {
    border: none;
  }

  .btn-position-td {
    height: 55px;
    position: relative;
  }
}

.dropDown {
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  font-family: Roboto;
  font-style: bold;
  font-size: 12px;
  font: #54c7fc;
}

.fa-sort-amount-desc,
.fa-sort-amount-asc {
  &:hover {
    cursor: pointer;
  }
}

.attd-report-table {
  width: 100%;
  overflow-x: scroll;

  table {
    background-color: white;
    margin-bottom: 0;

    thead {
      background-color: $blueShade-4;
      color: #d1e8ff;

      th {
        border: 1px dashed $blueShade-1;
        border-bottom: 1px solid $blueShade-1 !important;
        border-top: 1px solid $blueShade-1 !important;
        color: $primary-dark;
      }
    }

    tbody {
      position: relative;
    }

    tr,
    td {
      border: 1px dashed $blueShade-1;
      border-top: none;
      border-bottom: 1px solid $blueShade-1;
    }
  }

  .heading__top {
    height: 20px;
    border: none !important;

    &--empty {
      background-color: #fafafa;
      border: none !important;
    }

    &--text {
      background: #5789ba;
      border: 1px solid #5789ba;
      color: white;
      text-align: center;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 2px;
    }
  }

  .heading {
    border-right: none;
    border-left: none;

    &--month {
      th {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        text-align: center;
      }

      .heading--search {
        &:hover {
          text-decoration: none;
          cursor: initial;
        }
      }
    }

    &--search {
      background-color: #fafafa;
      border: 2px solid #fafafa;
      border-right: 1px solid $blueShade-1;
      border-top: none !important;
      padding: 3px;

      & ~ div {
        height: 30px;
      }

      .text-input {
        height: 30px;
      }

      input[type='text'] {
        width: 100%;
        height: 30px;
        padding: 0 5px;
        font-size: 14px;
        font-weight: lighter;
        border-radius: 4px;
        border: 1px solid darken(#fafafa, 10%);

        &::placeholder {
          font-size: 14px;
          color: #9bbdde;
        }
      }
    }

    &--total-and-percentage {
      th {
        color: #5789ba !important;
        text-align: center;
      }
    }
  }

  .attd__info {
    &--student-name {
      color: $primary-color;
    }

    &--student-attendance {
      text-align: center;

      .left,
      .right {
        display: inline-block;
        width: 50%;
        text-align: center;
      }

      .left {
        text-align: left;
        padding-right: 8px;
      }

      .right {
        text-align: right;
        padding-left: 8px;
      }
    }
  }

  .sub-table {
    width: 100%;

    tr {
      border: none !important;
      display: flex !important;
      justify-content: space-around !important;

      th {
        border: none !important;
      }

      td {
        border: none !important;
      }
    }
  }

  .empty-left {
    border-left: 1px solid #dedede;
  }

  .empty-right {
    border-right: 1px solid #dedede;
  }

  .empty-left-th {
    border-left: 1px solid $blueShade-1;
  }

  .empty-right-th {
    border-right: 1px solid $blueShade-1;
  }

  .present {
    color: $primary-color !important;
  }
}

.st-login-layout {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.bg-absolute {
  position: absolute;
  width: 100%;
  z-index: -1111;
}

.bg-absolute--light-inverse {
  top: 0;
  height: 40vh;
  background-color: $light-2;
}

.bg-absolute--light {
  top: 40%;
  height: 60vh;
  // background-color: $primary-color;
  background-color: #54c7fc;
}

.zIndex--1 {
  z-index: 1;
}

.zIndex--2 {
  z-index: 2;
}

.tc__generater.modal-body {
  max-height: 400px;
  overflow-y: scroll;
}

.bg-absolute--light + .container {
  padding-top: 50px;
}

.login-page {
  flex: 1 0 auto;
  margin-bottom: 50px;
}

.margin--0 {
  margin: 0 !important;
}

.flex--end {
  display: flex;
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start;
}

.button--space {
  .button {
    margin-right: 15px;
  }
}

.policy__table1 {
  .table-head--dark {
    background-color: #ffffff;
    color: #072645;
  }
}

.highlight--text {
  color: #006dc6;
  font-weight: bold;
  cursor: pointer;
}

.leaveApp__tableHead {
  text-align: left;
  color: #fff;
}

.leaveApp__buttonWrap {
  margin-top: 15px;
}

.sealtabs-logo {
  position: relative;
  top: 1px;
  height: 70px;
}

.assignPolicyDept {
  .group-selector {
    justify-content: flex-start;
  }
}

.policyHead {
  padding-right: 25px;
}

.flex--start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sub-head {
  font-size: 15px;
  color: #072645;
}

.policySelector,
.table-input-field {
  border: 1px solid #d1d1d1;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #fff;
  float: left;
  width: 100%;
}

.remark-selector {
  width: 95%;
}

.quote-and-form-container {
  background-color: $primary-color;
  box-shadow: 0 3px 5px $primary-dark;
  border-radius: 25px;
  display: flex;
  align-items: center;
  height: 350px;
}

.st-home-quote {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: $light-3;
  line-height: 38px;
}

.st-form {
  background-color: $white;
  border-radius: 25px;
  padding: 10px 20px 50px 20px;
  margin-top: auto;
  height: 100%;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.4);
}

.institution {
  min-height: 400px;
}

.gallery-img-ctr {
  margin: 20px;
}

.image-gallery-image img {
  max-height: 70%;
  max-width: 950px;
}

.gallery-img-subtext {
  padding: 10px 40px 10px 40px;
  font-size: 14px;
  color: #7ba2c9;
}

.gallery-tile-wrapper {
  border-radius: 6px;
  padding: 15px;

  img {
    box-shadow: 0 2px 5px rgba(1, 1, 1, 0.5);
    margin-bottom: 10px;
    max-height: 150px;
  }
}

.img-thumbnail {
  border: none !important;
  padding: 0px !important;
}

.galery-albumList {
  .col-md-4 {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.classProfileMidsection {
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid darken($light-1, 10%);
  border-radius: 6px;
  background-color: $light-1;
}

.classProfileMidsection__timetable {
  border: 1px solid darken($light-1, 10%);
  border-radius: 6px;
  background-color: $light-1;
}

.assignedTeachers {
  &__top {
    @extend .subjectsMainDiv__top;
    padding: 0;
  }

  .subjectAndTeacherDiv {
    border: 1px solid darken($light-1, 10%);
    display: flex;
    flex-wrap: wrap;
  }

  .singleSubjectAndTeacher {
    background-color: white;
    flex-grow: 1;
    padding: 8px 20px 8px 35px;
    min-height: 70px;
    border-right: 1px dashed $blueShade-1;
    border-bottom: 1px dashed $blueShade-1;
    display: inline-block;

    &:last-child {
      border-right: none;
    }
  }

  p,
  h4 {
    margin: 0;
  }

  .subjectName {
    color: $primary-dark;
    position: relative;

    .circle {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      left: -17px;
      transform: translateY(-50%);
      background: #d1e8ff;

      border-radius: 50%;
    }
  }

  .teacherName {
    color: $primary-color;
    font-size: 16px;
    font-weight: 400;
  }
}

.timeTable {
  &__top {
    @extend .subjectsMainDiv__top;
    padding: 0;
  }
}

.sectionDivRight {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .sectionButton {
    width: 100%;
    flex: 1;
    background: #ffffff;
    color: $primary-color;
    border: 1px solid darken($light-1, 10%);
    border-bottom: none;
    border-right: none;
    border-radius: 0;
    box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.12);
    position: relative;
    transition: all 0.2s;
    background-color: white;
    text-transform: uppercase;
    outline: none;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: -5px 0px 5px rgba(0, 0, 0, 0);
      background-color: $light-1;
      color: $blueShade-2;
      border-color: darken($light-1, 10%);
    }

    &__active-class {
      outline: none;
      box-shadow: -5px 0px 5px rgba(0, 0, 0, 0);
      background-color: $light-1;
      color: $blueShade-2;
      border-color: darken($light-1, 10%);
    }

    &:first-child {
      border-radius: 0 6px 0 0;
      border-top: none;
    }

    &:last-child {
      border-radius: 0 0 6px 0;
      border-bottom: none;
    }
  }
}

.sectionDivRight__horizontal {
  height: 100%;
  max-height: 30px;
  display: flex;
  flex-direction: row;

  .sectionButton {
    width: 100%;
    height: 35px;
    flex: 1;
    background: #ffffff;
    color: $primary-color;
    border: 1px solid darken($light-1, 10%);
    border-right: none;
    border-radius: 0;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.12);
    position: relative;
    transition: all 0.2s;
    background-color: white;
    text-transform: uppercase;
    outline: none;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: -5px 0px 5px rgba(0, 0, 0, 0);
      background-color: #d1e8ff;
      color: #5789ba;
      // background-color: #5789ba;;
      // color: white;
      border-color: darken($light-1, 10%);
    }

    &__active-class {
      outline: none;
      box-shadow: -5px 0px 5px rgba(0, 0, 0, 0);
      background-color: #d1e8ff;
      color: #5789ba;
      // background-color: #5789ba;;
      // color: white;
      border-color: darken($light-1, 10%);
    }
  }
}

.classProfileContainer {
  margin-top: -10px;

  &::after {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $light-1;
    z-index: -1;
  }

  .section-heading {
    font-size: 16px;
    color: #5789ba;
    text-transform: uppercase;
    margin: 20px 0;
  }
}

.quick-links-ctr {
  .tab {
    width: 200px;
    margin-left: 40px;
    top: 2px;
    height: 50px;
    border-color: $blueShade-1;

    &::before,
    &::after {
      top: -1px;
      z-index: 1;
      border-color: $blueShade-1;
      border-bottom: white;
    }
  }

  .links {
    background-color: white;
    padding: 20px 0;
    border-top: 1px solid $blueShade-1;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid darken($light-1, 10%);
  }
}

.subjects-tests-ctr {
  padding: 0 10px 20px 10px;
  border: 1px solid darken($light-1, 10%);
  border-radius: 6px;
  background: $light-1;
  position: relative;
  overflow: hidden;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px dashed $blueShade-1;
  }

  &__btn-ctr {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    text-align: justify;
    white-space: wrap;
    text-justify: inter-word;

    .button {
      display: inline-block;
      margin: 0 10px 10px 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.subjects-tests-select {
  height: 80%;
  display: flex;
  align-items: stretch;
  align-self: flex-start;
  margin-left: -30px;

  &__item {
    cursor: pointer;
    font-family: Roboto;
    font-size: 16px;
    color: $primary-color;
    text-transform: uppercase;
    padding: 10px;
    border: 1px solid $light-1;
    transition: all 0.1s;
    background-color: white;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    color: $primary-color;

    &:first-child {
      border-right: none;
      border-radius: 4px 0 0 0;
    }

    &:hover {
      background-color: white;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0);
      color: $blueShade-2;
    }

    &--active {
      background-color: $light-1;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0);
      color: $blueShade-2;
    }
  }
}

.subjectsMainDiv {
  padding: 0 10px 20px 10px;
  border: 1px solid darken($light-1, 10%);
  border-radius: 6px;
  // background: $light-1;
  background: white;
  position: relative;
  overflow: hidden;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 20px;

    &__teachers {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
    }
  }

  h3 {
    font-family: Roboto;
    font-size: 16px;
    color: #5789ba;
    text-transform: uppercase;
    margin: 20px 10px;
  }

  .displaySubjects {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    position: relative;
    top: 1px;
    left: 1px;
  }

  .singleSubject {
    border: 1px dashed $blueShade-1;
    padding: 8px 5px;
    flex: 0 0 33.333333%;
    margin-top: -1px;
    margin-left: -1px;

    &--full-width {
      flex: 0 0 99.8%;
    }
  }

  .singleElective {
    border: 1px dashed $blueShade-1;
    padding: 8px 5px;
    flex: 0 0 50%;
    margin-top: -1px;
    margin-left: -1px;

    &--full-width {
      flex: 0 0 99.8%;
    }
  }

  .subjectName {
    display: flex;
    align-items: center;
    padding: 0 20px;

    .subjectCircle {
      margin-right: 20px;
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #d1e8ff;
      border-radius: 50px;
    }

    p {
      color: $primary-dark;
      margin-bottom: 0;
    }
  }
}

.dashed-line {
  box-sizing: border-box;
  position: absolute;
  border: 1px dashed $blueShade-1;

  &--top-left,
  &--top-right {
    width: 10px;
    height: 60px;
    top: 1px;
    border-top: none;
  }

  &--top-left {
    left: 1px;
    border-left: none;
  }

  &--top-right {
    right: 3px;
    width: 10px;
    border-right: none;
  }

  &--bottom-left,
  &--bottom-right {
    width: 10px;
    height: 20px;
    bottom: 0px;
    border-bottom: none;
  }

  &--bottom-left {
    left: 1px;
    border-left: none;
  }

  &--bottom-right {
    right: 3px;
    border-right: none;
  }
}

.pdf-container {
  border: 1px solid #000;
  margin-top: 15px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.pdf-zoom {
  position: absolute;
  top: 80px;
  right: 60px;
  transform: translate(50%, -50%);
  z-index: 111;
  background-color: #fff;
  border: 2px solid #54c7fc;
  border-radius: 10px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.3);

  .fa {
    margin: 5px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      cursor: pointer;
    }
  }
}

.warning {
  text-align: center;
  margin-top: 20px;
}

.tab-nav-ctr--sms {
  height: 50px;
  border-top: none;

  .tab {
    border-top-color: $blueShade-3;

    &::before,
    &::after {
      border-color: $blueShade-3;
    }
  }
}

.sms-form-ctr {
  @extend .new-group-form-ctr;
  border: none;
  background-color: $light-1;
  margin: 0;
  padding: 0;

  &::after {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: $light-1;
    z-index: -11;
  }
}

.sms-inner-ctr {
  padding: 30px 100px;
  width: 100%;
  margin: 0 auto;
  background-color: white;

  // border: 1px solid $blueShade-3;
  border-top: none;

  .inner-ctr {
    border: 1px solid $blueShade-3;
    border-radius: 10px;
    padding: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .form__heading {
    color: $blueShade-2;
    font-weight: bold;
    font-size: 15px;

    span {
      font-weight: normal;
      color: $blueShade-3;
      font-size: 12px;
    }
  }

  .dashed-line {
    &-top,
    &-bottom,
    &-left,
    &-right {
      width: 100%;
      height: 2px;
      position: absolute;
      border: 1px dashed $blueShade-3;
    }

    &-top {
      border-top: none;
      top: 13px;
      left: 0;
    }

    &-bottom {
      border-bottom: none;
      bottom: 13px;
      left: 0;
    }

    &-left,
    &-right {
      height: 100%;
      width: 2px;
      top: 0;
    }

    &-left {
      border-right: none;
      left: 13px;
      transform: translateX(50%);
    }

    &-right {
      border-left: none;
      right: 13px;
      transform: translateX(-50%);
    }
  }

  .text-and-char-ctr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;

    .text-area {
      height: 200px;
      flex: 0 0 65%;

      textarea {
        height: 100%;
        width: 100%;
        border: 1px solid darken($light-1, 10%);
        border-radius: 5px;
        padding: 10px 15px;
        color: $blueShade-2;
        font-size: 16px;
        transition: all 0.2s;

        &::placeholder {
          color: $blueShade-2;
        }

        &:focus,
        &:hover {
          outline: none;
          box-shadow: 0 2px 5px rgba(1, 1, 1, 0.3);
        }
      }
    }

    .character-count {
      flex: 0 0 170px;
      height: 170px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border: 1px solid $blueShade-2;
      border-radius: 50%;

      .inner-char-ctr {
        height: 100%;
        width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: $blueShade-2;
        color: white;
        text-align: center;
      }

      overflow: hidden;

      h5 {
        height: 50%;
        text-transform: uppercase;
        width: 100%;
        margin: 0;
        padding: 20px;
        padding-top: 30px;
      }

      .count {
        padding-top: 5px;
        padding-bottom: 30px;
        margin: 0;
        background-color: white;
        height: 50%;
        color: $blueShade-2;
        width: 100%;

        span {
          font-size: 24px;
        }
      }
    }
  }
}

.group-selection-form-ctr {
  @extend .sms-form-ctr;
  background-color: none;

  .group-selection-inner-ctr {
    @extend .sms-inner-ctr;
    padding: 0px;
    width: 100%;
    position: relative;

    @include for-large-devices-down {
      width: 95%;
    }
  }

  .container-fluid {
    max-width: 100%;
  }

  .col-xs-9 {
    padding: 0;
  }

  .form-left {
    border: 1px solid $blueShade-3;
    position: relative;

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 1px;
      top: 0;
    }

    &::before {
      left: 15px;
      width: 25px;
      border: 1px dashed $blueShade-3;
      border-top: none;
      border-bottom: none;
    }

    &::after {
      right: 15px;
      border-left: 1px dashed $blueShade-3;
    }
  }

  .group {
    border-bottom: 1px dashed $blueShade-3;

    padding: 10px 10px 10px 20px;
  }

  .group-select-ctr {
    display: flex;
    flex-wrap: wrap;
    margin-left: 40px;
  }

  .submit-field {
    bottom: -89px;
    border-radius: 12px 12px 0 0;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
}

.side-msg {
  display: block;
  background-color: lighten($blueShade-3, 20%);
  color: $primary-dark;
  border: 1px solid $blueShade-2;
  padding: 10px 20px;
  position: absolute;
  top: -130px;
  right: -29%;
  width: 29%;

  &__heading {
    @extend .heading;
    font-weight: bold;
  }

  &__message {
    padding-bottom: 10px;
    word-break: break-all;
    text-transform: initial;
    font-weight: normal;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0;
      height: 1px;
      width: 100%;
      border-bottom: 1px dashed $blueShade-3;
    }
  }

  .msg-reciever {
    &__recipient {
      .num {
        font-size: 20px;
      }

      &--light {
        .num {
          color: $blueShade-2;
        }
      }
    }
  }
}

.group-select {
  cursor: pointer;
  user-select: none;
  margin: 0 -1px -1px 0;
  flex: 0 0 30%;
  border: 1px solid lighten($blueShade-3, 10%);
  padding: 5px 10px;
  display: flex;
  align-items: center;
  position: relative;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .square {
    flex: 0 0 15px;
    height: 15px;
    border: 1px solid $blueShade-3;
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  input[type='checkbox']:checked + .square {
    .tick {
      opacity: 1;
      font-weight: normal;
    }
  }

  .text {
    color: darken($blueShade-3, 10%);
    font-weight: normal;
  }

  &--all {
    border: none;
    padding: 0;
    margin-bottom: 5px;

    .text {
      margin-left: 10px;
      color: $primary-dark;
      font-size: 16px;
    }
  }
}

.confirm-group-inner-ctr {
  @extend .sms-inner-ctr;
  padding: 30px 50px 60px 50px;
  background-color: $light-1;

  .inner-ctr {
    border: none;
    padding: 0;

    &-upper,
    &-lower {
      background-color: white;
      border: 1px solid $blueShade-3;
      padding: 10px 30px;
      color: $primary-dark;
    }

    &-upper {
      margin-bottom: 50px;

      p {
        word-break: break-all;
        hyphens: auto;
      }
    }

    &-lower {
      display: flex;
      flex-direction: column;
    }
  }
}

.error__payslip {
  color: #ff0000;
  text-align: center;
  margin: 25px 0 0 0;
}

.unpaid__number {
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  color: #072645;
  padding: 5px 10px;
  outline: none;
  transition: all 0.2s;
  width: 80px;
  margin: 0 auto;
}

.unpaid__leaves {
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  color: #072645;
  padding: 5px 10px;
  outline: none;
  transition: all 0.2s;
  width: 80px;
  margin: 0 0 0 10px;
}

.unpaid__leave {
  color: #072645;
  outline: none;
  transition: all 0.2s;
  margin: 0 0 0 10px;
}

.text-center {
  text-align: center !important;
}

.mar-auto {
  margin: 0 auto !important;
}

.msg-recievers {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  &__recipient {
    margin-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &--light {
      .num {
        color: $blueShade-2;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .num {
      font-size: 24px;
    }
  }
}

.teacher-select-ctr {
  @extend .student-select-ctr;

  .teacher-outer-ctr {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      border-bottom: 1px solid $blueShade-3;
      height: 1px;
      z-index: 1;
    }

    .render-teachers-ctr {
      @extend .render-stdents-ctr;

      .table {
        color: $primary-dark;
        margin-bottom: 90px;
      }

      &::after {
        top: 2px;
      }

      .side-msg {
        margin: 40px 0 50px;
      }

      .submit-field {
        bottom: -10px;
      }
    }
  }
}

.student-select-ctr {
  position: relative;

  .select-outer-ctr {
    position: relative;
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 86px;
      background-color: $light-1;
      top: 0;
      left: 0;
      border-bottom: 1px solid $blueShade-3;
      z-index: 1;
    }
  }

  .select-search-ctr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
  }

  .search-bar {
    height: 30px;
    border: 1px solid $light-5;
    flex: 0 0 40%;
    padding-left: 10px;
    border-radius: 4px;

    &::placeholder {
      color: #9bbdde;
    }
  }

  .group-selector {
    flex: 1;
    border: none;
    justify-content: space-between;
    padding-right: 0;

    select {
      min-width: 150px;
    }
  }

  .render-stdents-ctr {
    background-color: white;
    position: relative;

    &::after {
      content: '';
      display: block;
      height: 100%;
      width: calc(100% + 30px);
      position: absolute;
      top: 5px;
      left: -1px;
      z-index: -3;
      border: 1px solid $blueShade-3;
      border-top: none;
    }
  }

  .table {
    min-height: 100px;
    position: relative;
    z-index: 3;
    color: $primary-dark;

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      border-right: 1px solid $blueShade-3 !important;
      position: absolute;
      right: -1px;
      top: 0;
    }
  }

  .side-msg {
    position: relative;
    width: 100%;
    top: 0;
    margin: 80px 0 50px 0;
    left: 0;
  }

  .student-select {
    @extend .group-select;
    border: none;
    justify-content: center;

    .square {
      margin-right: 0;
    }
  }

  .submit-field {
    bottom: 0;

    // position: absolute;
    &__studentconfirm {
      bottom: -90px;
      position: absolute;
    }

    p {
      display: flex;
      color: white;
      margin-right: 20px;
      align-items: flex-end;
      border-right: 1px solid white;
      padding-right: 20px;

      span:first-child {
        text-transform: uppercase;
        margin-right: 10px;
      }

      span:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $blueShade-2;
        font-size: 16px;
      }
    }
  }
}

.person-info {
  margin-bottom: 15px;

  .upper-blue-block {
    margin-top: 10px;
    background-color: $primary-dark;
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    border-radius: 5px 5px 0 0;
    color: white;
    font-size: 13px;
  }

  .bottom-blue-block {
    margin-top: 10px;
    background-color: $primary-dark;
    display: flex;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 5px 5px 0 0;
    color: white;
    font-size: 12px;
  }

  &-inner-ctr {
    background-color: white;
    border: 1px solid darken($light-1, 20%);
    padding: 10px 15px;

    div[class|='col'] {
      border-right: 1px dashed $blueShade-1;

      &:last-child {
        border-right: none;
      }

      @include for-medium-devices-down {
        border-right: none;
      }
    }
  }
}

.student {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid darken($light-1, 20%);

  &__background {
    background-color: #fff;
  }

  &__image {
    margin-left: 10px;
  }

  &__img {
    width: 80px;
    height: 80px;
    background-color: $light-1;
    border: 1px solid $blueShade-1;
  }

  &__right {
    display: flex;
    flex-direction: column;
    flex: 0 0 65%;
  }

  &__name {
    color: $primary-dark;
    font-size: 18px;
    margin-bottom: 0;
  }

  &__institution_name {
    text-transform: capitalize;
  }

  &__gender-dob-age {
    font-size: 12px;
    text-transform: uppercase;
    color: $blueShade-2;

    span {
      padding: 0 5px;
      border-right: 1px solid $blueShade-2;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border-right: none;
      }
    }
  }

  &__class-info {
    padding: 5px;
    background-color: lighten($blueShade-1, 20%);
    border: 1px solid lighten($blueShade-1, 5%);
    font-size: 13px;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    span[class~='fa'] {
      font-size: 16px;
      margin-right: 8px;
    }

    & > span {
      display: flex;
      align-items: center;
      margin-right: 8px;
      padding-right: 8px;
      border-right: 1px solid $blueShade-2;
    }

    span {
      &:last-child {
        color: $blueShade-2;
        border-right: none;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.profile {
  margin-top: -10px;
  position: relative;
  padding-bottom: 30px;

  ::after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: darken($light-1, 5%);
    z-index: -2;
  }

  &__person-info {
    display: flex;
    align-items: center;
    background-color: darken($light-1, 4%);
    padding: 10px 10px;
    color: lighten($blueShade-2, 10%);

    span {
      &:first-child {
        font-size: 20px;
      }

      &:last-child {
        font-size: 14px;
      }

      margin-right: 10px;
    }

    .dash {
      margin-right: 10px;
      height: 25px;
      width: 1px;
      background-color: lighten($blueShade-2, 20%);
    }
  }

  &__student-reviews {
    background-color: white;
    padding: 15px 20px;
    border-radius: 5px;
    border: 1px solid darken($light-1, 20%);
    display: flex;
    flex-direction: column;
  }

  &__teacher-tt {
    background-color: white;
    padding: 15px 20px;
    border-radius: 5px;
    border: 1px solid darken($light-1, 20%);
  }
}

.button--align-right {
  margin-top: 20px;
  margin-left: auto;
}

.attendance-calender {
  background-color: white;
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid darken($light-1, 20%);
  height: 100%;

  @include for-medium-devices-down {
    margin-top: 10px;
  }
}

.profile-top-btns {
  background-color: white;
  display: flex;
  justify-content: space-around;
  border: 1px solid darken($light-1, 10%);
  border-right: none;
  border-left: none;
  padding: 25px 10px;

  .dash {
    background-color: darken($light-1, 20%);
    width: 1px;
  }
}

.button-group {
  display: flex;

  &--justified {
    justify-content: space-around;
  }
}

.filter-modal-body {
  label {
    input {
      margin-right: 7px;
    }
  }

  &--small-input {
    width: 70px;
    margin: 5px;
  }
}

.filter-modal-div {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.filter-modal-header {
  display: flex;
  flex-direction: row !important;
}

.filter-modal-padding {
  padding: 10px;
}

.filter-modal-text-header {
  font-size: 14px;
  padding: 5px;
  color: #072645;
  text-align: left;
  border-bottom: 1px solid #072645;
}

.profile-table {
  .table-row {
    color: $primary-dark;
    transition: all 0.2s;
  }
}

.strike {
  text-decoration: line-through;
}

.delete-test-button {
  background-color: green;
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.delete-test-button::after {
  content: 'SELECT';
}

.add-test-button {
  background-color: red;
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.add-test-button::after {
  content: 'UNSELECT';
}

.st-class-and-test-form {
  .choose-tests-block {
    border-bottom: 1px dashed $primary-color;
    padding: 10px 0;

    &__heading {
      text-transform: uppercase;
      font-size: 16px;
    }
  }

  .choose-section-block {
    margin: 10px 0;
    padding-left: 15px;

    &__heading {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.new-sub-outer-ctr {
  background: $light-1;
  padding: 10px 0;
  border-bottom: 1px solid darken($light-1, 10%);
}

.new-sub__section {
  span[class~='fa'] {
    color: $blueShade-2;
  }
}

.add-electives-form {
  .elective-fields {
    input[name*='term'] {
      width: 65%;
    }

    .button--absolute {
      bottom: 30px;
    }
  }
}

.space-creator {
  height: 20px;
}

.space-creator-large {
  height: 100px;
}

.add-student {
  margin-top: -10px;
}

.st-white-wrapper {
  background-color: #fff;
  text-align: center;
  border: 1px solid gainsboro;
  display: flex;
  justify-content: space-around;
  padding-top: 2px;
}

.st-attendance__verticalline {
  height: 50px;
  border-right: 1px solid #c4c4c4;
  margin-top: 15px;
}

.attendance-status-text {
  color: #5789ba;
  font-size: 15px;
}

.attendance-status-big {
  color: #5789ba;
  font-size: 20px;
}

.noattendance-taken {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  height: 450px;
  line-height: 400px;
}

.buttonPills {
  padding: 5px 0 10px 20px;
  width: 100%;

  .buttonPills__button {
    border: 1px solid #006dc6;
    border-radius: 6px;
    background-color: transparent;
    padding: 3px 20px;
    margin: 0 10px 10px 0;
    color: #393939;

    :hover {
      border: 1px solid #54c7fb;
      background-color: #54c7fb;
      color: #fff;
    }

    :focus {
      border: 1px solid #54c7fb;
      background-color: #54c7fb;
      color: #fff;
    }
  }

  .buttonPills__button.buttonPills__active {
    border: 1px solid #006dc6;
    background-color: #006dc6;
    color: #fff;
  }
}

.attendanceSection--prinicpal {
  background-color: #fff;
}

.teacherName {
  color: #5789ba;

  span {
    font-weight: bold;
  }
}

.studentCount {
  h6,
  p {
    text-align: center;
  }
}

.hourlyattendance__wrap {
  display: block !important;
  text-align: center;
  padding: 15px 10px;
}

.form__heading--sms {
  color: #5789ba;
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
  margin: 10px;
}

.form__heading--sms-textarea {
  color: #5789ba;
  font-size: 16px;
  margin-bottom: 20px;
  text-align: left;
  margin: 10px;
  margin-left: 110px;
}

.sms-dash {
  .text-and-char-ctr-dash {
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;

    .text-area {
      height: 200px;
      flex: 0 0 60%;

      textarea {
        height: 100%;
        width: 100%;
        border: 1px solid darken($light-1, 10%);
        border-radius: 5px;
        padding: 10px 15px;
        color: $blueShade-2;
        font-size: 16px;
        transition: all 0.2s;

        &::placeholder {
          color: $blueShade-2;
        }

        &:focus,
        &:hover {
          outline: none;
          box-shadow: 0 2px 5px rgba(1, 1, 1, 0.3);
        }
      }
    }

    .character-count {
      flex: 0 0 170px;
      height: 170px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border: 1px solid $blueShade-2;
      border-radius: 50%;

      .inner-char-ctr {
        height: 100%;
        width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: $blueShade-2;
        color: white;
        text-align: center;
      }

      overflow: hidden;

      h5 {
        height: 50%;
        text-transform: uppercase;
        width: 100%;
        margin: 0;
        padding: 20px;
        padding-top: 30px;
      }

      .count {
        padding-top: 5px;
        padding-bottom: 30px;
        margin: 0;
        background-color: white;
        height: 50%;
        color: $blueShade-2;
        width: 100%;

        span {
          font-size: 24px;
        }
      }
    }
  }

  .btn-container {
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    &--margins {
      margin: 5px;
      height: 50px;
    }

    &--borderight {
      border-right: 1px solid #dedede;
    }

    .st-attendance-submit {
      width: auto;
      height: auto;
      color: white;
      background-color: $primary-color;
      border-radius: 4px;
      border: none;
      padding: 10px;
      margin: 20px;
    }
  }
}

.marks-entry-wrapper {
  border: 1px solid $light-4;
  border-radius: 6px;
  background-color: $light-1;
  min-height: 594px;
}

.block-title--marks-entry {
  border-bottom: 1px solid $light-4;
  border-radius: 6px 6px 0 0;
  height: 60px;
  background-color: white;
}

.marks-entry {
  margin: 0 20px;
  border: 1px solid $light-4;
  border-bottom: none;
  border-right: none;
}

.entries {
  display: flex;
  flex-wrap: wrap;

  &__entry {
    flex: 0 0 20%;
    padding: 8px;
    justify-content: center;
    display: flex;
    align-items: center;
    border: 1px solid $light-4;
    border-top: none;
    border-left: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: white;
  }
}

.entry__subject {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 10px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  &--pending {
    color: $orange;
    border: 1px solid $orange;
    border-radius: 20px;

    &:hover {
      color: $orange;
    }
  }

  &--completed {
    position: relative;
  }
}

.block-title--entry {
  height: 30px;
  text-transform: none;
}

.section-count {
  display: flex;
  word-break: normal;
  white-space: nowrap;
  align-items: center;

  &__remaining {
    color: $orange;
  }

  &__completed {
    color: $green;
  }
}

.tab--entry {
  margin: 0 30px;
  top: -5px;
  border-top: none;
  min-height: 40px;
  flex: 0 0 120px;

  &::after,
  &::before {
    z-index: 0;
    top: 0;
    border: none;
  }
}

.tab--orange {
  background-color: $orange;
  color: white;

  &::after,
  &::before {
    background-color: $orange;
  }
}

.tab--green {
  background-color: $green;
  color: white;

  &::after,
  &::before {
    background-color: $green;
  }
}

.st-tooltip {
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.3);
  opacity: 1 !important;
  color: $primary-dark !important;

  &-text {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;

    .icon {
      text-align: left;
      width: auto;
    }
  }
}

.support-dashboard {
  padding-top: 20px;

  &__left-content {
    background-color: $light-1;
    border: 1px solid darken($light-1, 10%);
  }

  a:hover {
    text-decoration: none;
  }
}

.school {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid darken($light-1, 10%);
  padding: 12px 12px;
  background-color: white;

  &__logo {
    flex: 0 0 80px;
    height: 80px;
    margin-right: 30px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $primary-dark;
  }

  &__name {
    font-size: 15px;
  }

  &__address {
    font-size: 12px;
  }
}

.assigned-tasks {
  background-color: $light-1;
  border: 1px solid darken($light-1, 10%);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notices-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #dedede;
  padding: 5px;

  &--smsEntry {
    border: none;
    border-right: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    height: 340px;
    overflow: scroll;
  }

  border-radius: 5px;
}

.notices-date-time {
  color: #7badd1;
  font-size: 12px;
  margin-left: 20px;
}

.notices-text {
  color: #00456a;
  font-size: 14px;
  margin-left: 20px;
}

.notices-icon {
  margin-left: -30px;
}

.recent-news-text {
  color: #7badd1;
  font-size: 15px;
  margin-left: 20px;
  text-decoration: none;
}

.task {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px 35px;
  color: $primary-dark;
  border-bottom: 1px dashed $blueShade-1;
  margin: 0 5px;

  &__subject {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -30px;
    margin-bottom: 10px;
  }

  &__assignee {
    margin-left: -30px;
  }

  &__assignee,
  &__assignor {
    color: $blueShade-2;
    font-size: 12px;
  }
}

.task-gallery {
  border-bottom: none;
}

.task-smsDash {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px 35px;
  color: $primary-dark;

  &__subject {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -30px;
    margin-bottom: 10px;
  }

  &__assignee {
    margin-left: -30px;
  }

  &__assignee,
  &__assignor {
    color: $blueShade-2;
    font-size: 12px;
  }
}

.gallery-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  // border: 1px solid #dedede;
  padding: 5px;
  // border-radius: 5px;
}

.gallery-alignment {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.gallery-box_text {
  text-decoration: none !important;

  &:focus,
  &:hover {
    box-shadow: 0 2px 5px rgba(1, 1, 1, 0.5);
  }
}

.img-thumbnail__dimension {
  max-width: none;
  width: 240px !important;
  height: 166px !important;
  margin-bottom: 10px;
}

.block-title--gallery {
  border-radius: 6px 6px 0 0;
  height: 60px;
  background-color: #d1e8ff;
  color: #7badd1;

  // font-size: 15px;
  h3,
  .dashboard-headings {
    color: #5789ba;
    margin: 0;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 16px;
    display: inline;
  }
}

.activity-log {
  @extend .assigned-tasks;
}

.activity {
  @extend .task;

  &__activist,
  &__time {
    @extend .task__assignee;
    margin-left: 0;
  }
}

.block-title {
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  margin: 0;

  &--dark {
    background-color: $primary-dark;
    color: white;
  }

  &--small {
    font-size: 13px;
  }

  &--large {
    padding: 8px 20px;

    span:first-child {
      font-size: 20px;
    }
  }

  &--light-blue-txt {
    color: lighten($blueShade-2, 10%);
  }
}

.icon {
  display: inline-block;
  width: 30px;
  flex: 0 0 30px;
  text-align: center;

  &--small {
    font-size: 14px;
  }

  &--large {
    font-size: 20px;
  }

  &--no-mg {
    margin-right: 0;
  }

  &--blue {
    color: $blueShade-2;
  }

  &--Xblue {
    color: $primary-color;
  }
}

.masonry-layout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px;
}

.Gallery-Dashboard {
  height: 453px;
}

.img-gallery {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
}

.principal-message-ctr {
  background-color: #eef7ff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;

  .principal-msg-img {
    width: 120px;
    height: 120px;
    background-color: $light-1;
    border: 1px solid $blueShade-1;
    border-radius: 50%;
  }

  .principal-msg-ctr {
    background-color: #eef7ff;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .principal-msg-left-ctr {
    background-color: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
  }

  .principal-msg-name {
    font-size: 16px;
    text-align: center;
    display: block;
    color: #5789ba;
  }

  .principal-msg-name-subtext {
    font-size: 12px;
    color: #5789ba;
  }

  .principal-msg-text-ctr {
    padding: 10px;
  }

  .principal-msg-heading {
    text-transform: uppercase;
    color: #5789ba;
    font-size: 16px;
    padding: 20px 0 5px 20px;
  }

  .principal-msg-text {
    font-size: 14px;
    padding: 0 15px 0 15px;
  }
}

.row-display {
  display: flex;
  justify-content: space-between;
}

.selector-wrapper {
  padding: 30px;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.hourSelector__margin {
  margin-left: 10px !important;
  margin-bottom: 20px;
}

.selector {
  position: relative;

  &__field {
    margin-bottom: 0;
    display: flex;
    border: 1px solid darken($light-4, 10%);
    align-items: center;
    height: 35px;
    box-sizing: border-box;
    color: $primary-color;

    &__icon {
      pointer-events: none;
      padding: 5px;
      height: 100%;
      width: 35px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      &--gray-bg {
        color: darken($light-4, 10%);
        background-color: white;
        border-right: 1px solid darken($light-4, 10%);
        margin-right: -35px;
      }

      &--blue-bg {
        margin-left: -35px;
        background-color: $primary-color;
        color: white;
      }

      &--white-bg {
        border-left: 1px solid darken($light-4, 10%);
        margin-left: -25px;
        background-color: white;
        color: $primary-color;
      }

      &--small {
        padding: 5px;
        width: 25px;
      }
    }

    &__input {
      flex: 1;
      appearance: none;
      padding: 0 35px+10px;
      height: 100%;
      cursor: pointer;
      outline: none;
      border: none;
      background-color: white;

      &--no-left-icon {
        padding: 0 30px 0 5px;
      }

      &--small-text {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: bold;
      }
    }

    &--tt {
      margin-bottom: 5px;
      height: 30px;
    }
  }
}

.helper-text {
  color: lighten($blueShade-2, 10%);
  margin-bottom: 0;

  &__num {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: white;
    background-color: lighten($blueShade-2, 10%);
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    margin-right: 10px;
  }

  &--abs-top,
  &--abs-bottom {
    position: absolute;
  }

  &--abs-top {
    top: -35px;
    left: 0;
  }

  &--abs-bottom {
    bottom: 0px;
  }
}

.selector-wrapper--attd {
  padding: 10px 30px;
  justify-content: flex-start;
}

.selector__field--date {
  & > div {
    height: 100%;

    & > div {
      height: 100%;

      .react-datepicker__input-container {
        height: 100%;
      }
    }
  }
}

.mar--0 {
  margin: 0 !important;
}

.d-block {
  display: block;
}

.timetable__date {
  position: relative;
}

.financialDetailsForm {
  .info-row {
    width: 100%;
  }

  input[disabled] {
    background-color: transparent;
    border: 0;
  }

  input[disabled].info-cell:hover,
  input[disabled].info-cell:focus {
    box-shadow: none;
  }
}

.policySelect {
  .group-selector {
    border: 0;
    padding: 0 15px 0 0;
  }
}

.helper-text--date-picker {
  margin-left: 30px;
  padding-left: 30px;
  border-left: 1px solid $primary-color;
  display: flex;
  align-items: center;
}

.position-groupselector {
  top: 40%;
  left: 45%;
  position: absolute;
  z-index: 101;
}

.leaveCard {
  border: 1px solid #9bbdde;
  // border: 1px solid #c7c7c7;
  border-radius: 8px;
  margin: 0 0 15px 0;

  .leaveCard__head {
    padding: 10px 15px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #072645;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: #fff;

    span {
      float: right;
      color: #ffffff;
    }
  }

  .leaveCard__body {
    padding: 15px;

    .leaveCard__paid {
      color: #006dc6;
      margin-top: 0;
    }

    p {
      color: #505050;

      span {
        color: #a2a2a2;
      }

      .warning {
        color: #f75835;
      }
    }
  }
}

.dateSelector .selector__field {
  margin-left: 10px;
}

.accordion-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.accordion {
  width: 45%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 40px 20px 0;

  .accordion--row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    background-color: #ffffff;
    flex-wrap: wrap;
    width: 100%;
    height: 40px;
    align-items: center;
    padding: 8px 10px;
    color: #006dc6;
    font-size: 16px;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
  }

  .accordion--child {
    width: 100%;
  }
}

.achievement {
  display: block;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid darken($light-1, 20%);
  margin-bottom: 15px;
  height: 150px;

  &__name {
    color: $primary-dark;
    font-size: 16px;
    margin-bottom: 0;
  }
}

.multiselect-dropdown {
  width: 400px;
}

.institutionBox {
  &:hover {
    cursor: pointer;
  }
}

.flex-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.separate-background {
  background-color: #a4c2f4;
}

.tickets-ctr {
  width: 85%;
  margin: 0 auto;
  border: 1px solid $blueShade-1;
  border-radius: 7px;

  .ticket-wrapper {
    background-color: $primary-dark;
    padding: 15px;
    color: $white;
    border-radius: 7px;

    .ticket-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.chats-ctr {
  width: 100%;
  display: flex;

  .desc-wrapper {
    width: 30%;
    padding: 10px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 5px 7px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .desc-title {
      font-size: 16px;
      margin: 5px 0;
    }

    .desc-title:nth-child(2) {
      margin-top: 30px;
    }
  }
}

.chats-ctr-2 {
  height: 60vh;
  width: 70%;
  border-left: 1px solid $blueShade-1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .chats-wrapper {
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    scroll-behavior: smooth;
    min-height: 85%;
    width: 100%;
    overflow-anchor: auto !important;
  }

  .input-wrapper {
    margin: 7px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-radius: 6px;
    width: 98%;
    height: 15%;

    .chat-input {
      border-radius: 5px;
      height: 50px;
      padding: 7px;
      width: 90%;
      border: 1px solid $blueShade-2;
      outline: none;
      overflow: auto;
      resize: none;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    &__edit {
      .chat-input {
        width: 80%;
      }
    }
  }
}

.status__applicationpurchased {
  color: #a0ac45;
}

.status__applicationpurchased::before {
  content: '';
  display: inline-block;
  vertical-align: text-top;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #a0ac45;
  margin-right: 5px;
}

.status__payable {
  color: #cf8309;
}

.status__payable::before {
  content: '';
  display: inline-block;
  vertical-align: text-top;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #cf8309;
  margin-right: 5px;
}

.status__feeAssigned {
  color: #d0a01d;
}

.status__feeAssigned::before {
  content: '';
  display: inline-block;
  vertical-align: text-top;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #d0a01d;
  margin-right: 5px;
}

.status__chequeSubmitted {
  color: #092ca1;
}

.status__chequeSubmitted::before {
  content: '';
  display: inline-block;
  vertical-align: text-top;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #092ca1;
  margin-right: 5px;
}

.status__onhold {
  color: #f47575;
}

.status__onhold::before {
  content: '';
  display: inline-block;
  vertical-align: text-top;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #f47575;
  margin-right: 5px;
}

.leave-status-payable {
  color: #d0a01d;
}
.leave-status-purchased {
  color: #a0ac45;
}
.leave-status-feepaid {
  color: #27ae60;
}
.leave-status-chequesubmitted {
  color: #092ca1;
}
.leave-status-feeAssigned {
  color: #d0a01d;
}
