.footer {
  height: 50px;
  width: 100%;
  overflow: hidden;
  background-color: #072645;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;

  @include for-medium-devices-down {
    height: 100px;
  }
}

.footer-left {
  flex: 0 0 550px;
  font-size: 10px;
  display: flex;
  justify-content: space-around;

  @include for-medium-devices-down {
    font-size: 12px;
    flex-direction: column;
    flex: 0 0 250px;

    &__info-item {
      padding: 5px 0;
    }

    &__divider {
      display: none;
    }
  }
}

.footer-right {
  flex: 0 0 150px;
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;

  @include for-medium-devices-down {
    align-self: flex-start;
    margin-top: 8px;
  }
}

.privateFooter {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  background-color: #006dc6;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
}

.header {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
  background-color: $primary-color;
  color: white;
  display: flex;
  position: relative;
  height: 80px;

  // z-index: -2;
  .header-left {
    margin-left: 15px;
    height: 80px;
    display: flex;
    flex-direction: column;
    flex: 0 0 300px;
    margin-right: auto;
    width: 290px;
    justify-content: flex-end;
  }

  .header-left-top {
    margin-left: 20px;
  }

  .header-left-top__img {
    height: 30px;
  }

  .header-left-bottom {
    height: 30px;
    line-height: 30px;
    background: #006dc6;
    font-size: 11px;
    position: relative;
    padding: 0 20px;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 100%;
      background: #006dc6;
      z-index: 0;
      top: 0;
    }

    &::before {
      border-radius: 5px 0 0 0;
      left: -10px;
      border-right: none;
      border-bottom: none;
      transform: skew(-11deg, 0deg);
    }

    &::after {
      border-radius: 0 5px 0 0;
      right: -10px;
      border-left: none;
      border-bottom: none;
      transform: skew(11deg, 0deg);
    }
  }

  .header-right {
    display: flex;
    flex-direction: column;
  }

  .header-right-top {
    height: 40px;
  }

  .header-utilities {
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%;
    padding-left: 0;
    margin-bottom: 0;
  }

  .header-utilities__item {
    cursor: pointer;
    font-size: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    text-transform: uppercase;
    // margin-right: 20px;
    margin-top: 8px;

    span[class~='fa-caret-down'] {
      margin-right: 0;
      margin-left: 10px;
      font-size: 14px;
    }
  }

  .searchbar {
    width: 235px;

    input[type='text'] {
      background-color: #ffffff;
      width: 100%;
      border-radius: 30px;
      border: none;
      height: 70%;
      padding: 0 10px;
      font-size: 13px;
      color: $dark-1;
      background-repeat: no-repeat;
      background-position: 96% center;

      &::placeholder {
        color: #79829e;
        opacity: 0.73;
      }

      &:focus {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  .user-info {
    background-color: $primary-color;
    border-radius: 0 0 0 8px;
    box-shadow: 0 0 4px $primary-color;
    margin-right: 0;
  }

  .user-info__img {
    width: 30px;
    height: 30px;
    background-color: white;
    margin-right: 10px;
    border-radius: 50%;
  }

  .user-info__name {
    .primarybutton {
      background-color: transparent;
      background-image: none;
      border: none;
      text-shadow: none;
      background-repeat: no-repeat;
      box-shadow: none;

      &:focus {
        color: white !important;
        background-color: transparent !important;
        border: none !important;
        border-color: none !important;
        box-shadow: none;
      }
    }

    button {
      color: white;
      background-color: transparent;
      border: none;
    }

    a {
      text-transform: none;
      color: black;
      text-decoration: none;
    }
  }

  .header-right-bottom {
    height: 40px;
    padding-right: 20px;
  }

  .nav-items {
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    padding-left: 0;
    margin-bottom: 0;

    a {
      color: #fff;
    }
  }

  .nav-items__item {
    text-decoration: none;
    cursor: pointer;
    font-size: 11.5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4px;
    text-transform: uppercase;

    span[class~='fa'] {
      margin-right: 10px;
      font-size: 14px;
    }

    span[class~='fa-caret-down'] {
      margin-right: 0;
      margin-left: 10px;
      font-size: 14px;
    }

    position: relative;

    &:hover,
    &--active,
    &--clicked {
      background-color: #fff;
      color: $primary-dark;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 12px;
        height: 100%;
        background-color: #fff;
        z-index: 1;
        top: 0;
      }

      &::before {
        border-radius: 5px 0 0 0;
        left: -8px;
        border-right: none;
        border-bottom: none;
        transform: skew(-11deg, 0deg);
      }

      &::after {
        border-radius: 0 5px 0 0;
        right: -8px;
        border-left: none;
        border-bottom: none;
        transform: skew(11deg, 0deg);
      }
    }

    &--clicked {
      background-color: $primary-dark;
      color: #fff;

      &::after,
      &::before {
        background-color: $primary-dark;
      }

      span[class*='caret-down'] {
        transform: rotate(180deg);
      }
    }

    &:hover {
      background-color: $orange;

      &::after,
      &::before {
        background-color: $orange;
      }
    }
  }

  .nav-item-ctr {
    height: 80%;
    margin-right: 20px;

    // position: relative;
    &:last-child {
      margin-right: 0px;
    }
  }
}

.submenu {
  transition: all 0.3s;
  position: absolute;
  left: 0;
  width: 100%;
  height: 0px;
  display: flex;
  overflow: hidden;
  bottom: 0;
  background-color: $primary-dark;
  color: white;
  padding: 0;
  justify-content: space-around;
  align-items: center;
  z-index: 111;

  &--visible {
    padding: 30px 0;
    bottom: -150px;
    height: 150px;
  }
}

.submenu-block {
  &__title {
    margin-bottom: 10px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .submenu-block-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__item {
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 12px;
      cursor: pointer;
      border: 2px solid white;
      padding: 5px 10px;
      margin-bottom: 10px;
      border-radius: 8px;
      margin-right: 10px;
      transition: all 0.2s;

      &:hover {
        border: 2px solid $blueShade-1;
        text-decoration: none;
        color: white;
      }
    }
  }

  &:nth-child(2) {
    flex: 0 0 20%;
  }
}

.time-table {
  border: 1px solid darken($light-1, 10%);
  background-color: white;

  .time-table__cell {
    border: none;
    font-weight: normal;
  }

  .time-table__cell__v2 {
    border: none;
    font-weight: normal;
  }

  .time-table__cell {
    padding: 20px 10px;
    text-align: center;
    border-right: 1px dashed $blueShade-1;
    border-bottom: 1px solid $blueShade-1;
    color: $primary-dark;

    &--blue,
    &--Xblue {
      background: #d1e8ff;
      color: $blueShade-2;
      text-transform: uppercase;
      border-bottom: 1px solid $blueShade-1;
    }

    &--Xblue {
      background-color: darken(#d1e8ff, 10%);
      color: white;
      border-bottom: none;
      border-right: none;
    }

    &--dark {
      background-color: #5789ba;
      color: white;
    }

    &--light {
      text-transform: uppercase;
      background-color: lighten($light-1, 5%);
      color: $primary-dark;
    }

    &--Xdark {
      background-color: $primary-dark;
      color: #d1e8ff;
    }

    &--th {
      border-bottom: none;
    }
  }

  .time-table__cell__v2 {
    padding: 20px 10px;
    text-align: center;
    border-right: 1px dashed $blueShade-1;
    border-bottom: 1px solid $blueShade-1;
    color: $primary-dark;

    &--blue,
    &--Xblue {
      background: #d1e8ff;
      color: $blueShade-2;
      text-transform: uppercase;
      border-bottom: 1px solid $blueShade-1;
    }

    &--Xblue {
      background-color: darken(#d1e8ff, 10%);
      color: white;
      border-bottom: none;
      border-right: none;
    }

    &--dark {
      background-color: #5789ba;
      color: white;
    }

    &--light {
      text-transform: uppercase;
      background-color: lighten($light-1, 5%);
      color: $primary-dark;
    }

    &--Xdark {
      background-color: $primary-dark;
      color: #5789ba;
    }

    &--th {
      border-bottom: none;
    }
  }

  &-row {
    .time-table__cell {
      &:last-child {
        border-right: none;
      }
    }

    &:last-child {
      .time-table__cell {
        border-bottom: none;
      }
    }
  }

  &__subject {
    color: $primary-dark;
  }

  &__teacher {
    color: $primary-color;
  }
}

.timetableWrapper-scroll {
  width: 100%;
  overflow-x: scroll;
}

.tick {
  display: inline-block;
  opacity: 0;
  width: 15px;
  height: 15px;
  position: relative;

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 1px;
    width: 15px;
    height: 6px;
    background-color: $primary-dark;
    transform: rotate(-48deg);
  }

  &::before {
    top: 0;
    left: 1px;
    background-color: white;
    z-index: 1;
  }

  &--visible {
    opacity: 1;
  }
}

.heading {
  text-transform: uppercase;
  color: $primary-dark;
  padding-bottom: 10px;

  &--small {
    font-size: 14px;
  }

  &--with-underline {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0;
      height: 1px;
      width: 100%;
      border-bottom: 1px dashed $blueShade-3;
    }
  }
}

.snackbar {
  align-items: center;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 111;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  bottom: -30px;
  opacity: 0;
  transition: all 0.3s;
  display: flex;

  &__text {
    margin: 0 20px 0 0;
    font-size: 16px;

    &--no-btn {
      margin: 0;
    }
  }

  &--show {
    opacity: 1;
    bottom: 30px;
    animation: none;
  }

  &--autoDismiss {
    transition-delay: 2s;
    opacity: 0;
    bottom: 0;
  }
}

.st-table {
  min-width: 90%;
  overflow-x: scroll;

  &--default {
    background-color: #fff;
  }

  &--full {
    min-width: 100%;
  }

  &__head {
    &--lightblue {
      background-color: #d1e8ff;
      border: 1px solid #9bbdde;
    }

    &--pointer {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__row {
    &--headcell {
      border: 1px dashed #9bbdde;
      border-top: none;
      border-bottom: 1px solid #9bbdde;
      padding: 8px;

      .fa {
        margin-left: 8px;
      }
    }

    &--headcell-Space {
      border: 1px dashed #9bbdde;
      border-top: none;
      border-bottom: 1px solid #9bbdde;
      padding: 8px;
      min-width: 80px;

      .fa {
        margin-left: 8px;
      }
    }
  }

  &__body {
    &--row {
      vertical-align: top !important;
    }

    &--cell {
      border: 1px dashed #9bbdde;
      border-top: none;
      border-bottom: 1px solid #9bbdde;
      padding: 8px;
    }

    &--extra-spacing {
      border: 1px dashed #9bbdde;
      border-top: none;
      border-bottom: 1px solid #9bbdde;
      padding: 8px;
      min-width: 40px;
    }
  }
}

.st-table__text {
  &--head {
    color: #7badd1;
    text-align: center;
  }

  &--headBlack {
    color: #000;
    text-align: center;
  }

  &--headCenter {
    color: #7badd1;
    text-align: center;
  }

  &--body {
    color: #072645;
    text-align: left;
  }

  &--attendance {
    color: #71dafe;
    text-align: center;
  }

  &--bodyName {
    color: #71dafe;
    text-align: left;
    vertical-align: middle !important;
  }

  &--bodycenter {
    text-align: center;
    vertical-align: middle !important;
  }

  &--bold {
    font-weight: bold;
    font-size: 16px;
  }
}

.st-container-table {
  display: flex;
  justify-content: center;
}

.status {
  &__applicationpurchased {
    color: #58e084;
  }

  &__applicationpurchased::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #58e084;
    margin-right: 5px;
  }

  &__closed {
    color: #e74c3c;
  }

  &__closed::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #e74c3c;
    margin-right: 5px;
  }

  &__new {
    color: #f1c40f;
  }

  &__new::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #f1c40f;
    margin-right: 5px;
  }

  &__chalangenerated {
    color: #3498db;
  }

  &__chalangenerated::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #3498db;
    margin-right: 5px;
  }

  &__inprocess {
    color: #9b59b6;
  }

  &__inprocess::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #9b59b6;
    margin-right: 5px;
  }

  &__approved {
    color: #58e084;
  }

  &__approved::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #58e084;
    margin-right: 5px;
  }

  &__appointmentprocess {
    color: #2980b9;
  }

  &__appointmentprocess::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #2980b9;
    margin-right: 5px;
  }

  &__feereview {
    color: #e74c3c;
  }

  &__feereview::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #e74c3c;
    margin-right: 5px;
  }

  &__feepending {
    color: #d35400;
  }

  &__feepending::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #d35400;
    margin-right: 5px;
  }

  &__admitted {
    color: #58e084;
  }

  &__admitted::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #58e084;
    margin-right: 5px;
  }

  &__feepaid {
    color: #27ae60;
  }

  &__feepaid::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #27ae60;
    margin-right: 5px;
  }

  &__pendingprincipal {
    color: #8e44ad;
  }

  &__pendingprincipal::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #8e44ad;
    margin-right: 5px;
  }

  &__principalApproved {
    color: #fdcb6e;
  }

  &__principalApproved::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #fdcb6e;
    margin-right: 5px;
  }
}

.st-enquiry-table-wrapper {
  display: flex;
  justify-content: center;
}

.st-attendance__wrapper--hourly {
  max-height: 475px;
  overflow-y: scroll;
  padding: 10px;
  border: 1px solid #bababa;
  border-radius: 5px;
}

.printable-table-text {
  color: $primary-dark;
}

.pending-attd__X {
  text-align: center;
  color: red;
}

.challan {
  background-color: #fff;
  border: 1px solid $blueShade-1;
  padding: 10px 20px;
  border-radius: 8px 8px 0 0;
  position: relative;
  padding-bottom: 50px;

  &--header {
    margin: 20px;
    display: flex;
    justify-content: center;

    &__institutionname {
      margin: 10px;
      font-size: 16px;
      text-transform: uppercase;
    }

    &__address {
      text-align: center;
      font-size: 12px;
    }

    &__institutionnamecenter {
      margin: 10px;
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  &--body {
    &__title {
      margin: 10px;
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
    }

    &__address {
      text-align: center;
      font-size: 12px;
    }
  }
}

.comment {
  position: relative;
  margin-top: 30px;
  margin-right: 20px;
  // margin-left: 50px;
}

.comment-avatar {
  position: absolute;
  top: 0;
  left: -40px;
  width: 70px;
  height: 70px;
  border: 1px solid #9bbdde;
}

.comment-author {
  margin-bottom: 5px;
  padding-left: 45px;
  padding-right: 20px;
  font-size: 16px;
  font-weight: bold;
}

.comment-text {
  padding: 12px;
  padding-left: 45px;
  background-color: #f8f8f8;
  border-top: 5px solid #9bbdde;
}

.comment-date {
  margin-top: 5px;
  font-size: 12px;
  color: #bdc3c7;
}

.comment-typearea {
  textarea {
    height: 100%;
    width: 100%;
    border: 1px solid darken($light-1, 10%);
    border-radius: 5px;
    padding: 10px 15px;
    color: $blueShade-2;
    font-size: 16px;
    transition: all 0.2s;

    &::placeholder {
      color: $blueShade-2;
      opacity: 0.3;
    }

    &:focus,
    &:hover {
      outline: none;
      box-shadow: 0 2px 5px rgba(1, 1, 1, 0.3);
    }
  }
}

.comment-button-container {
  display: flex;
  justify-content: center;
}

.tab {
  margin-left: 20px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light-1;
  // border-radius: 10px 10px 0 0;
  border-top: 1px solid $light-5;
  border-bottom: none;
  position: relative;
  top: 1px;
  color: #5789ba;

  &--gallery {
    background-color: transparent;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 15px;
    height: 100%;
    background-color: $light-1;
    z-index: -1;
    top: -1px;
    border: 1px solid #d1d1d1;
  }

  &::before {
    border-radius: 5px 0 0 0;
    left: -10px;
    border-right: none;
    border-bottom: none;
    transform: skew(-11deg, 0deg);
  }

  &::after {
    border-radius: 0 5px 0 0;
    right: -10px;
    top: -1px;
    border-left: none;
    border-bottom: none;
    transform: skew(11deg, 0deg);
  }
}

.tab__text {
  font-size: 15px;
  margin-bottom: 0;

  text-transform: uppercase;
}

.group-selector {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  border: 1px solid $light-5; // border-top: none;
  border-bottom: none;
  padding: 0 15px;
  background-color: white;

  &--gallery {
    border: 1px solid $light-5; // border-top: none;
  }

  &--border {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #9bbdde;
  }

  .selector__field {
    height: 25px + 5px;
    background-color: white;
    z-index: 3;

    &__icon--gray-bg {
      margin-right: -25px;
    }

    &__input {
      padding: 0 25px + 5px;
    }
  }

  .fa-caret-right {
    color: $blueShade-1;
  }

  &__marks-entry {
    border: 1px solid $light-5; // border-top: none;
  }

  &__II-lang-arrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__II-lang-caret {
    padding-right: 25px - 15px;
  }
}

.group-selector-border {
  border-top: none;
  border-bottom: 1px solid #9bbdde;
}

.classDivselector {
  display: flex;
  align-items: center;
  margin-bottom: 35px;

  &--gallery {
    border: 1px solid $light-5; // border-top: none;
  }

  .selector__field {
    background-color: white;
    width: 45%;
    margin-right: 3%;
    color: $primary-dark;

    &__icon--white-bg {
      color: $primary-color;
    }

    &__icon--gray-bg {
      margin-right: -25px;
    }

    &__input {
      padding: 0 25px + 5px;
    }
  }

  .fa-caret-right {
    color: $primary-color;
  }

  &__marks-entry {
    border: 1px solid $light-5; // border-top: none;
  }

  &__II-lang-arrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__II-lang-caret {
    padding-right: 25px - 15px;
  }
}

.attd-search {
  // border: 1px solid $light-5;
  display: table-cell;
  vertical-align: middle;
  height: 80px;
  width: 100vw;
  border-left: none;
  border-right: none;
  border-bottom: none;
  // background-color: #fafafa;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -35px;
    width: 100%;
    height: 37px;
    background-color: #fafafa;
    border-bottom: 1px solid $light-5;
    z-index: -111;
  }

  input[type='text'] {
    height: 35px;
    border: 1px solid $light-5;
    width: 70%;
    padding-left: 10px;
    border-radius: 4px;

    &::placeholder {
      color: #9bbdde;
    }

    @include for-medium-devices-down {
      width: 100%;
    }
  }
}

.search-bar {
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  width: 100vw;
  border-left: none;
  border-right: none;
  border-bottom: none;
  position: relative;

  input[type='text'] {
    height: 35px;
    border: 1px solid $light-4;
    width: 70%;
    padding-left: 10px;
    border-radius: 4px;

    &::placeholder {
      color: #9bbdde;
    }

    @include for-medium-devices-down {
      width: 100%;
    }
  }
}

.attd-submit-container {
  position: absolute;
  top: -2px;
  left: -10px;
  width: 220px;
  height: 55px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 -5px 15px -5px rgba(0, 0, 0, 0.3);

  .num {
    flex: 0 0 50%;
    height: 100%;
    background-color: #e5f7ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 0 4px;

    .num-absent,
    p {
      color: $red;
      margin-bottom: 0;
    }

    .num-late,
    .num-text-late {
      color: $orange;
      margin-bottom: 0;
    }

    .num-present,
    .num-text-present {
      color: green;
      margin-bottom: 0;
    }

    .num-total {
      color: $primary-light;
    }
  }

  .btn-container {
    border-radius: 0 0 4px 0;
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: $primary-dark;

    .st-attendance-submit {
      width: 70px;
      height: 35px;
      color: white;
      background-color: $primary-color;
      border-radius: 4px;
      border: none;
    }
  }
}

.calendar-wrapper {
  height: 500px;
}

.event {
  font-size: 16px;
  padding: 10px 0;

  &--holiday {
    background-color: purple;
  }

  &--custom {
    // background-color: skyblue;
    background-color: '#3c78d8';
  }
}

.classAndSectionDiv {
  a:hover {
    text-decoration: none;
  }

  a {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 6px;
  background-color: white;

  .classHeader {
    height: 132px;
    background: white;
    display: flex;
    flex-direction: column;
    border-radius: 6px 6px 0 0;
    border: 1px solid #072645;
    width: 100%;
    margin-bottom: 5px;
    position: relative;
    padding-top: 10px;
  }

  .button {
    justify-self: flex-end;
    align-self: center;
    margin-top: auto;
    position: relative;
    transform: translateY(50%);
  }

  .parentStudentCtr {
    padding-top: 15px;

    span[class~='fa'] {
      color: #072645;
      font-size: 68px;
      margin-right: 25px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .parentName {
    color: #072645;
    font-size: 28px;
    text-transform: uppercase;
    margin-top: 0;
  }

  .studentCount {
    margin: 0;
    color: #072645;
    align-self: center;
    font-size: 13px;
  }

  .sectionHeaderDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 57px;
    background: #5789ba;
    text-align: center;

    // background-color: #d1e8ff;
    .sectionHeader {
      font-family: Roboto;
      line-height: 19px;
      font-size: 16px;
      color: $white;
      // color: #5789ba;
      text-transform: uppercase;
    }
  }

  .sections {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border: 1px solid darken($light-1, 10%);
    border-radius: 0 0 6px 6px;
  }

  .singleSection {
    font-family: Roboto;
    width: 33%;
    flex-grow: 1;
    text-align: center;
    border: 1px dashed $blueShade-1;
    background-color: white;
    padding: 10px 0;

    &:hover {
      background-color: #ededed;
      box-shadow: 0 2px 5px rgba(1, 1, 1, 0.5);
    }

    h4 {
      font-size: 16px;
      color: $primary-dark;
    }

    h5 {
      font-size: 12px;
      color: $primary-color;
    }
  }
}

.classProfile {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid darken($light-1, 10%);
  height: 100px;
  background-color: white;

  .parentStudentCtr {
    span[class~='fa'] {
      color: #072645;
      font-size: 55px;
    }

    display: flex;
    justify-content: flex-start;

    .class__name {
      margin-left: 10px;
      font-size: 25px;
      margin-top: 12px;
    }
  }

  .class__count {
    display: flex;
    align-items: center;
  }
}

.classList {
  width: 100%;
  margin: 0 0 30px 0;
  padding: 25px;
  border-radius: 2px;
  box-shadow: 0 10px 20px -10px rgba(50, 84, 190, 0.2);
  border: 1px solid #dbe4ff;
  background-color: #ffffff;
  display: flex !important;
  display: -webkit-flex;
  flex-wrap: wrap;

  .classList__column1 {
    width: 18%;
    margin-right: 2%;
    float: left;
    background-color: #ced9f4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    // padding: 6% 25px;
    padding: 60px 25px;

    &:hover {
      cursor: pointer;
    }

    h2 {
      font-size: 22px;
      line-height: 30px;
      text-transform: capitalize;
      margin: 0 0 8px 0;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 12px;
    }
  }

  .classList__column2 {
    border-left: 1px solid #dbe4ff;
    // border-right: 1px solid #dbe4ff;
    width: 80%;
    padding: 0 2%;
    float: left;
    display: flex;
    align-items: center;

    .sectionList__display {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .button--subSection {
      display: block;
      cursor: pointer;
      text-transform: capitalize;
      width: 143px;
      border-radius: 8px;
      margin: 0 15px 15px 0;
      box-shadow: 0 12px 10px -10px rgba(49, 136, 255, 0.5);

      h4 {
        border-bottom: 1px solid #ffffff;
        padding: 0 0 3px 0;
        margin: 0 0 5px 0;
        font-size: 16px;
      }

      p {
        font-size: 12px;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.attendanceSection {
  border: 1px solid darken($light-1, 10%);
  border-radius: 6px;
  background-color: $light-1;

  &__teacherDash {
    border: none;
    z-index: 10;
    position: absolute;
    margin: 70px 0 10px 130px;
  }

  .attendanceHeader {
    // background: #5789ba;
    background: #d1e8ff;
    border-radius: 6px 6px 0 0;
    padding: 10px 8px;

    h3 {
      color: white;
      margin: 0;
      text-transform: uppercase;
      display: inline;
    }
  }

  .attendanceHeader--dashboard {
    // background: #e0f5ff;
    background: #d1e8ff;
    border-radius: 6px 6px 0 0;
    padding: 12px 10px;

    h3,
    .dashboard-headings {
      color: #5789ba;
      margin: 0;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 16px;
      display: inline;
    }
  }

  span[class~='fa-fast-forward'] {
    margin-right: 0;
    margin-left: 10px;
  }

  .dateAndWeekChangeDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
    padding-right: 0;

    .datePickerDiv {
      display: flex;
      align-items: center;

      span {
        color: #5789ba;
        margin-right: 10px;
        font-weight: bold;
      }
    }

    .datePicker {
      margin-bottom: 0;
      border: 1px solid $light-5;
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 30px;
      flex: 0 0 30%;
      border-radius: 4px;

      span[class~='fa'] {
        margin: 0 8px;
        font-size: 16px;
        color: darken($light-5, 10%);
        flex: 1;
      }

      .dash {
        height: 100%;
        width: 1px;
        display: inline-block;
        background-color: $light-5;
      }

      .dash + div {
        overflow: hidden;
        width: 100px;
      }
    }

    input[type='text'] {
      flex: 0 0 40%;
      color: $primary-color;
      outline: none;
      border: none;
      cursor: pointer;
      background-color: white;
      font-weight: bold;
    }

    .weekChangeDiv {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        margin-right: 0;
        font-size: 12px;
      }
    }
  }

  .entryPresent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .attendanceDisplay {
    margin: 0 20px 20px 20px;
    min-height: 420px;
    background: white;
    border: 1px solid #9bbdde;
    display: flex;
    flex-direction: row;
  }

  .attendanceDisplay--teacherDash {
    margin: 0 0 20px 20px;
    min-height: 420px;
    background: #fafafa;
    border: 1px solid #9bbdde;
    display: flex;
  }

  .singleDay {
    width: 16.666%;
    min-height: 420px;
    display: flex;
    flex-direction: column;

    .dateAndDay {
      height: 84;
      background: #edf6ff;
      text-align: center;
      color: #5789ba;

      .displayDate {
        padding: 13px 0;
        font-size: 16px;
        text-transform: uppercase;
        border-right: 1px dashed $blueShade-1;
      }

      .displayDay {
        padding: 13px 0;
        background: #d1e8ff;
        font-weight: bold;
        border-right: 1px dashed $blueShade-1;
        border-bottom: 1px dashed $blueShade-1;
      }
    }

    .attendanceEntry {
      flex: 1;
      display: flex;
      border-right: 1px dashed $blueShade-1;
      justify-content: center;
      align-items: center;

      .circle {
        margin: 0 auto;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        border: 1px solid transparent;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      h6 {
        font-weight: bold;
      }

      .tag {
        font-weight: bold;
        text-transform: uppercase;
      }

      .count {
        font-size: 16px;

        .first {
          font-size: 20px;
          font-weight: bold;
        }
      }

      &--finished {
        .count,
        .tag {
          color: $green;
        }

        h6 {
          color: #69db5e;
        }

        .circle {
          border-color: $green;
        }
      }

      &--not-done {
        h6,
        .count,
        .tag {
          color: $red;
        }

        .circle {
          border-color: $red;
        }
      }
    }

    &:last-child {
      .displayDate,
      .displayDay,
      .attendanceEntry {
        border-right: none;
      }
    }
  }
}

.approve-sms {
  position: relative;

  &::after {
    content: '';
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    background-color: $light-1;
  }

  .table {
    color: $primary-dark;
  }

  .msg-recievers {
    background-color: #edf6ff;
    border: 1px solid #d1e8ff;
    padding: 5px 0;
    width: 95%;
    margin: 20px 0 0 10px;

    p {
      margin-bottom: 0;
    }

    .num {
      font-size: 18px;
    }
  }
}

.approve-sms-msg {
  word-break: break-all;
}

.status {
  color: white;
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 0;
  text-transform: uppercase;
  text-align: center;

  &--orange {
    background-color: $orange;
  }

  &--green {
    background-color: $green;
  }

  &--red {
    background-color: red;
  }
}

.student-performance {
  border-radius: 5px;
  // border: 1px solid darken($light-1, 20%);
  border: 1px solid #e1e1e1;
  padding: 15px 20px;
  height: 400px;
  background-color: white;
  margin-bottom: 10px;

  &--dashboard {
    padding: 0;
  }

  .chart-ctr {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .chart {
    height: 250px;
    margin-top: 30px;
    margin-left: 20px;
  }
}

.coming-soon {
  height: 70vh;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-content: center;
  text-shadow: 1px 4px 6px #c4c4c4, 0 0 0 #000, 1px 4px 6px #c4c4c4;
}

.legend {
  // flex: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 12px;
  color: $primary-dark;
  z-index: 1111;

  &__item {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .box {
    width: 12px;
    height: 12px;
    margin-right: 8px;
    margin-bottom: 2px;

    &--r {
      background: #ff3838;
    }

    &--o {
      background: #ff9f1a;
    }

    &--p {
      background: #c56cf0;
    }

    &--b {
      background: #7158e2;
    }

    &--g {
      background: #3ae374;
    }

    &--stroke--o {
      color: #ff7300;
      margin-right: 8px;
    }

    &--stroke--b {
      color: #8884d8;
      margin-right: 8px;
    }
  }
}

.o-spacebetween-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.o-horizontal-center {
  margin: 0 auto;
}

.test {
  background-color: #ffffff;
}

.test2 {
  background-color: #3ae374;
}

.add-student-form {
  &-outer {
    background-color: darken($light-1, 5%);
  }

  &-inner {
    border: 1px solid $blueShade-1;
    border-top: none;
    border-bottom: none;
    background-color: white;
    padding: 10px 20px;
  }

  &-student-list-BG {
    border: 1px solid $blueShade-1;
    background-color: white;
    padding: 10px 20px;
  }

  background-color: $light-1;
  border: 1px solid $blueShade-1;
  padding: 15px;
  border-radius: 8px 8px 0 0;
  position: relative;
  padding-bottom: 50px;

  .submit-field {
    height: 50px;
  }
}

.side-info {
  background-color: $light-1;
  padding: 25px 20px;
  border: 1px solid $blueShade-1;
  border-radius: 8px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &__title {
    @extend .info-title;
    margin-top: 0;
  }

  &__text {
    color: $blueShade-2;
  }

  .button {
    align-self: flex-end;
  }

  .dash {
    margin: 10px 0;
    height: 1px;
    border-bottom: 1px dashed $blueShade-1;
  }
}

.innerHeightFull {
  min-height: calc(100vh - 151px);
}

.links {
  &__list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 1px solid $light-4;
    padding-left: 0;

    &__item {
      padding: 8px 20px;
      list-style-type: none;
      border-bottom: 1px solid $light-4;
      text-transform: uppercase;
      color: $primary-color;
      background-color: white;

      &:hover {
        background-color: $light-1;
      }

      a {
        color: $primary-color;
        width: 100%;
        height: 100%;
        display: inline-block;

        &:hover,
        &:focus {
          text-decoration: none;
          color: darken($primary-color, 10%);
        }
      }
    }
  }
}

.transfer-certificate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.warning {
  // height: 70px;
  width: 100%;
  display: flex;
  // align-items: center;
  flex-direction: column;

  &--red,
  &--blue {
    padding: 15px 20px;
    margin-bottom: 0;
  }

  &--red {
    background-color: $red;
    color: white;
  }

  &--blue {
    background-color: lighten($primary-color, 20%);
  }
}

.table-scroll {
  max-width: 98%;
  margin: 5px auto 100px auto;
  color: #fff;
  // max-width: 99%;
  overflow-x: scroll;

  // padding: 10px;
  table {
    border-color: #cacaca;
    position: relative;
    overflow: scroll;
    max-width: 100%;
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;

    thead {
      th {
        position: sticky;
        top: 0;
        z-index: 2;

        table {
          max-width: 100%;
          overflow: hidden;

          tr {
            td {
              border: 1px solid #cacaca;
              background-color: #072645;
            }
          }
        }
      }
    }

    tbody {
      th {
        position: sticky;
        left: 0;
        z-index: 1;
      }

      td {
        table {
          max-width: 100%;
          overflow: hidden;

          td {
            background-color: #fff;
          }
        }
      }
    }

    th {
      // position: sticky;
      // top: 0;
      padding: 5px;
      background-color: #072645;
      border: 1px solid #cacaca;
      word-break: break-word;
      min-width: 80px;
      text-align: center;
      border-color: #cacaca;
      color: #fff;

      &:nth-child(3) {
        min-width: 150px;
      }
    }

    td {
      // position: sticky;
      // top: 0;
      padding: 5px;
      word-break: break-word;
      text-align: center;
      min-width: 35px;
      border-color: #cacaca;
      border-color: #cacaca;
    }
  }
}

.column__withoutpadd {
  padding: 0 !important;
}

.attendanceTaken {
  color: #69db5e;

  span {
    color: #69db5e;
    font-weight: bold;
  }
}

.text-decoration-none {
  text-decoration: none;
}

.quill-editor {
  margin: 1rem 0rem;
}

.quill-editor .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.quill-editor .ql-snow.ql-toolbar {
  display: block;
  background: #9fc5e8;
  // background: #edf6ff;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-snow .ql-color-picker .ql-picker-item {
  border-radius: 50%;
}

.quill-editor .ql-editor {
  min-height: 18em;
}

.themeSwitcher {
  margin-top: 0.5em;
  font-size: small;
}

.pill-container {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin: 7px 0 17px 0;

  .pill {
    display: flex;
    gap: 3px;

    &__title {
      font-weight: bold;
      padding: 0.4em 1.4em;
      border-radius: 15px;
      background-color: $primary-dark;
      color: $white;
    }

    &__button {
      opacity: 0.8;
      color: $red;
      outline: none;
      border: none;
      background-color: white;
      width: 10px;
      height: 10px;
      font-size: 18px;
    }

    &__button:hover {
      opacity: 1;
      font-weight: bold;
    }
  }
}

.mom {
  &__header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 10px 0;
  }

  &__header-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h5 {
      padding: 0.5em 1em;
      width: 30%;
      margin: 4px 0;
    }

    .green-background {
      background-color: $green-light;
    }
  }

  &__subtitle {
    background-color: $light-3;
    padding: 0.5em 1em;
  }

  &__wrapper {
    padding: 0 2em;

    h6 {
      display: inline-block;
      margin: 0 3px;
    }
  }
}

.chats-wrapper {
  display: flex;
  flex-direction: column;
  padding: 4px;

  .chat {
    width: 100%;
    min-height: 25px;
    padding: 5px 10px;
    margin: 3px 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 5px;
    font-size: 15px;

    .chat-time {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 11px;
    }
  }

  .chat-profile {
    font-size: 12px;
    padding: 0 5px;
  }

  .chat-wrapper-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding: 7px 3px;
    border-radius: 7px;
  }

  .highlighted {
    background-color: #ffede59d;
  }

  .chat-wrapper-left {
    width: 100%;
    margin: 7px 0;
  }

  .chat-right {
    background-color: $blueShade-4;
    border-radius: 10px 10px 0 10px;
    width: 45%;
  }

  .chat-left {
    width: 45%;
    background-color: $light-3;
    border-radius: 10px 10px 10px 0;
  }

  .chat-message {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.chat-right {
  .chat-message {
    .edit-button {
      outline: none;
      border: none;
      background-color: transparent;
      opacity: 0.7;
    }
  }
}

.chat-right:hover {
  .chat-message {
    .edit-button {
      opacity: 1;
    }
  }
}

.status-viewer {
  width: 150px;
  padding: 0.5em 2em;
  background-color: $primary-color;
  border-radius: 5px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;

  &.closed {
    background-color: $green;
  }

  &.pending {
    background-color: $orange;
  }

  &.onhold {
    background-color: $red;
  }
}

.docs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.document-viewer {
  width: 100%;
  padding: 0.5em 1.8em;
  border-radius: 5px;
  text-align: center;
  border: 1px solid $blueShade-2;
  cursor: pointer;
  text-transform: uppercase;

  &__red {
    color: #f40f02;
    margin-right: 8px;
  }

  &__blue {
    color: #3279ea;
    margin-right: 8px;
  }

  &__gray {
    margin-right: 8px;
    color: #898989;
  }
}

/*notification tab*/
.notification-badge {
  position: relative;
  display: inline-block;
}

.badge {
  position: absolute;
  top: -5px;
  right: -15px;
  background-color: $primary-dark;
  color: white;
  border-radius: 50%;
  padding: 3px;
  font-size: 10px;
}

.notification-tab {
  padding-bottom: 15px;
  &__header-container {
    font-size: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    margin-bottom: 5px;
    background-color: $primary-dark;
    color: $white;
    border-radius: 15px 15px 0 0;
  }

  &__body {
    overflow-y: scroll;
  }

  &__body::-webkit-scrollbar {
    display: none;
  }

  &__header-wrapper {
    h4 {
      font-size: 18px;
      text-transform: uppercase;
      margin: 4px 0;
    }
    p {
      font-size: 13px;
      color: $white;
      margin: 3px 0;
    }
  }

  &__header--orange {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding-top: 10px;
    color: $orange;
    // color: $white;
    padding-left: 10px;
  }

  &__header--green {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding-top: 10px;
    color: $green;
    // color: $white;
    padding-left: 10px;
  }

  &__button {
    margin: 0 8px;
    background-color: transparent;
    color: white;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    border: 2px solid white;
    padding: 5px 10px;
    border-radius: 8px;
    transition: all 0.2s;

    &:hover {
      border: 2px solid $blueShade-1;
      text-decoration: none;
      color: white;
    }
  }

  .button-active {
    background-color: $primary-dark;
    color: white;
  }

  .notification-wrapper {
    padding: 8px 10px;
    color: $primary-dark;
    border-bottom: 1px dashed $blueShade-1;
    cursor: pointer;
    display: flex;
    align-items: start;
    gap: 10px;
    .dot {
      color: $primary-color;
    }
  }

  .notification-wrapper:hover {
    background-color: $blueShade-4;
  }

  .notification {
    font-size: 14px;
    margin: 5px;
    color: $primary-dark;
    &__date {
      color: $blueShade-2;
    }
  }
}
