.text {
  &--subject {
    color: $blueShade-2;
    font-size: 16px;
    margin-top: 5px;
  }

  &--success {
    color: $green;
  }

  &--approved {
    color: $primary-color;
  }

  &--failure {
    color: $red;
  }

  &--pending {
    color: $orange;
  }
  &--dark {
    color: $primary-dark;
  }
}

hr.option_divider {
  overflow: visible;
  padding: 0;
  border: none;
  border-top: medium double #333;
  color: #333;
  text-align: center;
}

hr.option_divider:after {
  content: 'OR';
  display: inline-block;
  position: relative;
  top: -0.7em;
  font-size: 18px;
  padding: 0 15px;
  background: #ffffff;
}

hr.leave_divider {
  border: 0.5px solid #79829e;
}

.u-position-relative {
  position: relative;
}

.checkbox-padding {
  padding: 10px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'nunitoBold';
  letter-spacing: 0.2px;
}

.red-text {
  color: $red;
}

.green-text {
  color: $green;
}
.black-text {
  color: #000;
}

.text-bold {
  font-family: 'nunitoBold';
}

.text-uppercase {
  text-transform: uppercase;
}
